import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {Location} from '@angular/common';
import { Platform, MenuController, PopoverController, ModalController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { UtilityService } from './core/services/utility.service';
import { UserService } from './core/services/user.service';
import { RuoliCostanti } from './core/models/ruoli-costanti';
import { Dipendente } from './responsabili/models/dipendente';
import { LogoutPopoverComponent } from './shared/operatori/components/logout-popover/logout-popover.component';
import { ModalConfermaComponent } from './shared/responsabili/components/modal-conferma/modal-conferma.component';
import { MatDialog } from '@angular/material';
import { version } from '../../package.json';
import { fromEvent, Subscription } from 'rxjs';
import { Principal } from 'dst-security/lib/models/principal';
import { PrincipalService } from 'dst-security';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CommonService } from './core/services/common.service';
import { ConfermaAnnullaModalComponent } from './shared/operatori/components/conferma-annulla-modal/conferma-annulla-modal.component';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { filter } from 'rxjs/operators';
import { ModaleCambiaPasswordComponent } from './shared/operatori/components/modale-cambia-password/modale-cambia-password.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnDestroy {
  public selectedIndex = 0;
  public selectedIndexChild = 0;
  public funzionalita = new RuoliCostanti();
  public user : Principal = {} as Principal;
  public utenteLoggato : Dipendente;
  public version: string = version;

  keySub: Subscription;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList < IonRouterOutlet > ;


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private utilityService: UtilityService,
    public menuCtrl: MenuController,
    public userService : UserService,
    private popoverCtrl : PopoverController,
    public dialog : MatDialog,
    private location: Location,
    private principalService: PrincipalService,
    private appVersion: AppVersion,
    private commonService: CommonService,
    private modalController: ModalController,
    private mobileAccessibility: MobileAccessibility
  ) {
    console.log('version:', version)
    this.initializeApp();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.utilityService.previousUrl = this.utilityService.currentUrl;
      this.utilityService.currentUrl = event.url;

    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.mobileAccessibility.usePreferredTextZoom(false);


      if (this.platform.is("cordova")) {
        this.commonService.getVersione().subscribe( res => {
          //console.log('la res della get versione', res);
          //this.appVersion.getVersionNumber().then(vernum => {
          //  alert('la version num'+vernum);
          //});
          this.appVersion.getVersionCode().then( version => {
            //console.log('app version get code', version);


            if (version < res.version) {
              //se versione dell'app minore, modale per l'aggiornamento
              this.versionUpdatingModal(version as number, res);
            }

          });
        });
      }

      /**GESTIONE BACK */
      document.addEventListener('ionBackButton', (ev: any) => {
        ev.detail.register(10, () => {
          /**se sono in login o home page, l'applicazione si chiude */
          if (this.router.url == '/security/login' || this.router.url == '/operatori/targa' || this.router.url == "/operatori/targa?fromResponsabili=true") {
            navigator['app'].exitApp();
          }
          else {
            /**se la pagina in cui ci troviamo è di un tab diverso da home ed è la pagina
             * principale di quel tab, il back fa il redirect nella home
             */
            if (this.router.url == "/operatori/storico" || this.router.url == "/operatori/attivita" || this.router.url == "/operatori/altro") {
              this.router.navigate(['operatori/targa/'], { queryParams: { fromResponsabili: true }});
            }
            /**se la pagina precedente non è quella della modifica/apertura/chiusura servizio
             * consentito il location back
             */
            else if (!this.utilityService.previousUrl.includes("/operatori/attivita/gestione-servizio/") && !this.utilityService.previousUrl.includes("/operatori/targa/servizi-cliente/servizio/")) {
              this.location.back();
            }
          }
        });
      });
    });


  }

  async versionUpdatingModal(versionCode: number, versionObject: {link: string, version: number, mandatory: boolean}) {
    const modal = await this.modalController.create({
      component: ConfermaAnnullaModalComponent,
      cssClass: 'modalConfermaAnnulla',
      componentProps: {
        obbligatorio: versionObject.mandatory,
        testo: `E' necessario aggiornare l'applicazione per poter utilizzare le nuove funzionalità. Clicca Ok per procedere`,
      },
      backdropDismiss: !versionObject.mandatory
    });

    modal.onDidDismiss().then( result => {
      console.log('il result dalla modale', result);
      if (result.data) {
        window.open(versionObject.link,'_system', 'location=yes');
      }
    });

    this.splashScreen.hide();
    this.statusBar.styleDefault();
    return await modal.present();
  }


  ngOnInit() {
    // da eliminare
    if (!this.platform.is("mobileweb") && !this.platform.is("desktop")) {
      let token: string = localStorage.getItem("x-auth-token");
      if (token != null && token != undefined && token.length > 0) {
        this.router.navigate(['operatori']);
      } else {
        this.router.navigate(['security/login']);
      }
    }
    // da eliminare

    // const currentPath = window.location.pathname.split('/')[1];
    // if (currentPath == 'operatori') {
    // }else if (currentPath == 'responsabili') {
    //   const path = window.location.pathname.split('responsabili/')[1];
    //   if (path !== undefined) {
    //     this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    //   }
    // }else{
    // }
    // this.router.navigate([currentPath]);

     //test enter click disabilitato
     const keydown$ = fromEvent(document, 'keydown');
     this.keySub = keydown$.subscribe( event => {
       if ((event as KeyboardEvent).key == "Enter") {
         event.preventDefault();
       }
     });
  }



  higlightMenuBtn(j: number, child: string) {
    if (child == 'child') {
      this.selectedIndex = undefined;
      this.selectedIndexChild = j;
    } else {
      this.selectedIndex = j;
      this.selectedIndexChild = undefined;
    }
  }

  toggleMenu() {
    this.menuCtrl.toggle();
  }

  login(route) {
    this.router.navigate([route]);
  }

  getDipendenteLoggato() {
    setTimeout(() => {
      this.user = JSON.parse(localStorage.getItem('user'));
    });
    //user loggato
    this.userService.getUserLogged().subscribe( res => {
      this.utenteLoggato = res;
    },
    error => {
      //('error get dipendente', error);
    });
  }

  async logoutPopover(event) {
    const popover = await this.popoverCtrl.create({
      component: LogoutPopoverComponent,
      cssClass: 'my-custom-class',
      event: event,
      translucent: true,
      mode: "ios",
      componentProps: {
        popoverController: this.popoverCtrl
      }
    });
    popover.onDidDismiss().then( res => {
      if (res.data && res.data.logout) {
        //conferma logout modale
        this.openModaleConferma();
      }
      else if (res.data && res.data.cambiaPassword) {
        this.cambiaPassword();
      }
    });
    return await popover.present();
  }

  async cambiaPassword() {
    const modal = await this.modalController.create({
      component: ModaleCambiaPasswordComponent,
      cssClass: 'cambia-password-modal'
      });
      return await modal.present();
  }

  async openModaleConferma() {
    const dialogRef = this.dialog.open(ModalConfermaComponent, {
      width: '250px',
      data: {messaggio : "Disconnettere profilo?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //logout
        this.principalService.logout();
        localStorage.clear();
        this.router.navigate(['security/login']);
      }
    });
  }

  async goToOperatori() {
    const dialogRef = this.dialog.open(ModalConfermaComponent, {
      width: '300px',
      data: {messaggio : "Vuoi passare al software per gli operatori?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['operatori/targa/'], { queryParams: { fromResponsabili: true }});
      }
    });
  }

  ngOnDestroy() {
    this.keySub.unsubscribe();
    localStorage.clear();
  }
}
