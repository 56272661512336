import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFocusColor]'
})
export class FocusColorDirective {

  element : HTMLElement;

  constructor(private elementRef : ElementRef) {
    this.element = this.elementRef.nativeElement;
   }

  @HostListener('ionFocus')
  onFocus() {
    this.element.style.borderColor = '#f69600';
  }

  @HostListener('ionBlur')
  onBlur() {
    this.element.style.borderColor = '#ebebeb';
  }

}
