export class RuoliCostanti {
    /* RUOLI PER FUNZIONALITA STRUTTURA */
    public STRUTTURA_CREATE = "STRUTTURA-IN";
    public STRUTTURA_UPDATE = "STRUTTURA-MD";
    public STRUTTURA_DELETE = "STRUTTURA-DL";
    public STRUTTURA_VIEW = "STRUTTURA-W";
    
    /* RUOLI PER FUNZIONALITA CLIENTE */
    public CLIENTE_CREATE = "CLIENTI-IN";
    public CLIENTE_UPDATE = "CLIENTI-MD";
    public CLIENTE_DELETE = "CLIENTI-DL";
    public CLIENTE_VIEW = "CLIENTI-W";
    
    /* RUOLI PER FUNZIONALITA CONTRATTO */
    public CONTRATTO_CREATE = "CONTRATTO-IN";
    public CONTRATTO_UPDATE = "CONTRATTO-MD";
    public CONTRATTO_DELETE = "ONTRATTO-DL";
    public CONTRATTO_VIEW = "CONTRATTO-W";
    
    /* RUOLI PER FUNZIONALITA SERVIZIO */
    public SERVIZIO_CREATE = "SERVIZIO-IN";
    public SERVIZIO_UPDATE = "SERVIZIO-MD";
    public SERVIZIO_DELETE = "SERVIZIO-DL";
    public SERVIZIO_VIEW = "SERVIZIO-W";
    
    /* RUOLI PER FUNZIONALITA DIPENDENTE */
    public DIPENDENTE_CREATE = "DIPENDENTE-IN";
    public DIPENDENTE_UPDATE = "DIPENDENTE-MD";
    public DIPENDENTE_DELETE = "DIPENDENTE-DL";
    public DIPENDENTE_VIEW = "DIPENDENTE-W";
    
    /* RUOLI PER FUNZIONALITA ARTICOLO */
    public ARTICOLO_CREATE = "ARTICOLO-IN";
    public ARTICOLO_UPDATE = "ARTICOLO-MD";
    public ARTICOLO_DELETE = "ARTICOLO-DL";
    public ARTICOLO_VIEW = "ARTICOLO-W";
    
    /* RUOLI PER FUNZIONALITA TARIFFA E LISTINO */  
    public TARIFFA_CREATE = "TARIFFA-IN";
    public TARIFFA_UPDATE = "TARIFFA-MD";
    public TARIFFA_DELETE = "TARIFFA-DL";
    public TARIFFA_VIEW = "TARIFFA-W";
    
    /* RUOLI PER FUNZIONALITA VEICOLO */
    public VEICOLO_CREATE = "VEICOLO-IN";
    public VEICOLO_UPDATE = "VEICOLO-MD";
    public VEICOLO_DELETE = "VEICOLO-DL";
    public VEICOLO_VIEW = "VEICOLO-W";
    
    /* RUOLI PER FUNZIONALITA MARCA E MODELLO */
    public MARCA_MODELLO_CREATE = "MARCA_MODELLO-IN";
    public MARCA_MODELLO_UPDATE = "MARCA_MODELLO-MD";
    public MARCA_MODELLO_DELETE = "MARCA_MODELLO-DL";
    public MARCA_MODELLO_VIEW = "MARCA_MODELLO-W";
    
    /* RUOLI PER FUNZIONALITA SITUAZIONE ECONOMICA CLIENTE */
    public SITUAZIONE_ECONOMICA_UPDATE = "SITUAZIONE_ECONOMICA-MD";
    public SITUAZIONE_ECONOMICA_VIEW = "SITUAZIONE_ECONOMICA-W";
    
    /* RUOLI PER FUNZIONALITA GESTIONE TRASFERIMENTI */
    public GESTIONE_TRASFERIMENTI_UPDATE = "GESTIONE_TRASFERIMENTI-MD";
    public GESTIONE_TRASFERIMENTI_VIEW = "GESTIONE_TRASFERIMENTI-W";
    
    /* RUOLI PER FUNZIONALITA PRENOTAZIONI */
    public PRENOTAZIONI_UPDATE = "PRENOTAZIONI-MD";
    public PRENOTAZIONI_DELETE = "PRENOTAZIONI-DL";
    public PRENOTAZIONI_VIEW = "PRENOTAZIONI-W";
    
    /* RUOLI PER FUNZIONALITA FLUSSO DYLOG */
    public FLUSSO_DYLOG_UPDATE = "FLUSSO_DYLOG-MD";
    public FLUSSO_DYLOG_VIEW = "FLUSSO_DYLOG-W";

    /* RUOLI PER FUNZIONALITA FOGLIO FIRMA */
	public FOGLIO_FIRMA = "FOGLIO_FIRMA";
	
	/* RUOLI PER FUNZIONALITA ZTL */
    public ZTL = "ZTL";
    
    /* RUOLI PER FUNZIONALITA FLOTTA */
	public INSERIMENTO_FLOTTA = "INSERIMENTO_FLOTTA";
	public APPROVAZIONE_FLOTTA = "APPROVAZIONE_FLOTTA";
	
	/* RUOLI PER FUNZIONALITA INVENTARIO */
    public INVENTARIO = "INVENTARIO";
    
	/* RUOLI PER FUNZIONALITA REPORT */
    public REPORT = "REPORT";
    
    /* RUOLI PER REPORT */
	public REPORT_SUPERVISORE = "SUPERVISORE";
	public REPORT_RESP_GARAGE = "RESP_GARAGE";
}