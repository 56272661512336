import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetList } from 'src/app/core/interfaces/generic-links';
import { IndirizzoStruttura } from 'src/app/core/models/indirizzo-struttura';
import { MarcaList } from 'src/app/core/models/marca';
import { Rendiconto, RendicontoList } from 'src/app/core/models/rendiconto';
import { StrutturaLinks, StrutturaList } from 'src/app/core/models/struttura';
import { Veicolo } from 'src/app/core/models/veicolo';
import { HateoasService } from 'src/app/core/services/hateoas.service';
import { environment } from 'src/environments/environment';
import { Indirizzo } from '../models/indirizzo';
import { ServizioList } from '../models/servizio';
import { Soggetto, SoggettoList } from '../models/soggetto';
import { ContrattiList } from 'src/app/responsabili/models/contratto';
import { DescDatoList } from 'src/app/core/models/desc-dato';
import { Gruppo, GruppoLinks, GruppoList } from 'src/app/core/models/gruppo';
import { DipendenteList } from '../models/dipendente';
import { Accettazione } from 'src/app/core/models/accettazione';
@Injectable({
  providedIn: 'root'
})
export class RendicontiService extends HateoasService  {

  constructor(private hateoasService: HateoasService, http: HttpClient) {
    super(http);
  }

  getAllRendiconti(page: number, size: number, targa: string, clusterValore: string, idStruttura: number | string, servizio: string) {
    const url = `${environment.endpointUri}/rendicontoes/search/findRendiconti?targa=${targa}&cluster=${clusterValore}&idStruttura=${idStruttura}&servizio=${servizio}&page=${page}&size=${size}&projection=StoricoRendiconto&sort=dataInizio,desc`;
    return this.hateoasService.get<GetList<RendicontoList>>(url);
  }

  getAllStrutture() {
    const url = `${environment.endpointUri}/strutturas/search/findAll`;
    return this.hateoasService.get<GetList<StrutturaList>>(url);
  }

  getAllStruttureProjection() {
    const url = `${environment.endpointUri}/strutturas/search/findAll?projection=Struttura`;
    return this.hateoasService.get<GetList<StrutturaList>>(url);
  }

  getStruttureDipendente(idDipendente: number) {
    const url = `${environment.endpointUri}/dipendentes/${idDipendente}/strutture`;
    return this.hateoasService.get<GetList<StrutturaList>>(url);
  }

  findByTarga(targa: string) {
    const url = `${environment.endpointUri}/veicoloes/search/findByTarga?targa=${targa}&projection=Veicolo`;
    return this.hateoasService.get<Veicolo>(url);
  }

  getMarcaAuto(text, autocompleteObj: any = null) {
    const urlMarca = `${environment.endpointUri}/marcas/search/findByMarca?marca=${text}`;
    return this.hateoasService.get<GetList<MarcaList>>(urlMarca, autocompleteObj);
  }

  findClienti(text, cluster, strutturaId : number, autocompleteObj) {
    //autocomplete clienti (abbonato o autonoleggio)
    let url = `${environment.endpointUri}/accettaziones/search/filtraSoggettoByNome?&denominazione=${text}&idStruttura=${strutturaId}&cluster=${cluster}`;
    return this.hateoasService.get<string>(url, autocompleteObj).pipe(
      map(response => {
        let lista = [];
        if (response) {
          lista.push(...response);
        }
        return lista;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getIndirizzoStruttura(idStruttura: number) {
    const url = `${environment.endpointUri}/strutturas/${idStruttura}/indirizzo`;
    return this.hateoasService.get<Indirizzo>(url);
  }

  findServiziByMacroarea(cluster: string, macroarea: string, dimVeicolo: string, strutturaId : number, contrattoId: number) {
    //FIND SERVIZI BY TYPE STRUTTURA AND CLUSTER
    const url = `${environment.endpointUri}/servizios/search/findByStrutturaAndClusterAndContratto?tipologia=${macroarea}&strutturaId=${strutturaId}&cluster=${cluster}&dimensione=${dimVeicolo}&contrattoId=${contrattoId}`;
    return this.hateoasService.get<GetList<ServizioList>>(url);
  }

  findContrattiAttiviSoggetto(strutturaId: number, cluster: string, denominazioneCliente: string) {
    //get dei contratti attivi per cluster autonoleggio, convenzionato o abbonato
    const url = `${environment.endpointUri}/contrattoes/search/findContrattiAttiviSoggetto?strutturaId=${strutturaId}&cluster=${cluster}&nome=${denominazioneCliente}`;
    return this.hateoasService.get<GetList<ContrattiList>>(url);
  }

  findContrattiAttiviSoggettoByProjection(strutturaId: number, cluster: string, denominazioneCliente: string) {
    //get dei contratti attivi per cluster autonoleggio, convenzionato o abbonato
    const url = `${environment.endpointUri}/contrattoes/search/findContrattiAttiviSoggetto?strutturaId=${strutturaId}&cluster=${cluster}&nome=${denominazioneCliente}&projection=DettaglioContratto`;
    return this.hateoasService.get<GetList<ContrattiList>>(url);
  }


  getDescDatiByMacroarea(macroarea: string) {
    const url = `${environment.endpointUri}/descDatoes/search/findByValoreServizio?valore=${macroarea}&projection=DescDati`;
    return this.hateoasService.get<GetList<DescDatoList>>(url);
  }

  findGruppoByNomeEStruttura(strutturaId: number, text: string, autocompleteObj = null) {
    const url = `${environment.endpointUri}/gruppoes/search/findByNomeEStruttura?struttura=${strutturaId}&nome=${text}`;
    return this.hateoasService.get<GetList<GruppoList>>(url, autocompleteObj);
  }

  getGruppoById(id: number) {
    const url = `${environment.endpointUri}/gruppoes/${id}`;
    return this.hateoasService.get<Gruppo>(url);
  }

  findContrattiByCodiceConvenzione(idGarage: number, text: string, autocompleteObj = null) {
    const url = `${environment.endpointUri}/contrattoes/search/findContrattiByCodice?idStruttura=${idGarage}&codice=${text}&projection=ContrattoLight`;
    return this.hateoasService.get<GetList<ContrattiList>>(url, autocompleteObj);
  }

  checkAbbonamentoByTargaAndStruttura(targa : string, strutturaId : number) {
    //verifica l'esistenza di un abbonamento per la specifica targa e struttura
    const urlAbbonato = `${environment.endpointUri}/accettaziones/search/verificaContrattoAbbonamentoAttivo?targa=${targa}&strutturaId=${strutturaId}`;
    return this.hateoasService.get<boolean>(urlAbbonato);
  }

  recuperaAbbonato(targa: string, idStruttura: number) {
    const url = `${environment.endpointUri}/accettaziones/search/recuperaAbbonato?targa=${targa}&strutturaId=${idStruttura}`;
    return this.hateoasService.get<Soggetto>(url);
  }

  consentiAccesso(idContratto, idStruttura: number, dimensione: string, targa: string) {
    const url = `${environment.endpointUri}/accettaziones/search/consentiAccesso?idContratto=${idContratto}&idStruttura=${idStruttura}&dimensione=${dimensione}&targa=${targa}`;
    return this.hateoasService.get<any>(url);
  }

  findDipendenti(text, autocompleteObj) {
    const url = `${environment.endpointUri}/dipendentes/search/findDipendenti?search=${text}`
    return this.hateoasService.get<GetList<DipendenteList>>(url, autocompleteObj);
  }

  createVeicolo(veicolo) {
    const url = `${environment.endpointUri}/veicoloes`
    return this.hateoasService.createObject<Veicolo>(url, veicolo);
  }

  patchVeicolo(veicolo, id) {
    const url = `${environment.endpointUri}/veicoloes/${id}`
    return this.hateoasService.patchObject<Veicolo>(url, veicolo);
  }

  postAccettazione(accettazione) {
    const url = `${environment.endpointUri}/accettaziones`;
    return this.hateoasService.createObject<Accettazione>(url, accettazione);
  }

  patchAccettazione(accettazione, id) {
    const url = `${environment.endpointUri}/accettaziones/${id}`;
    return this.hateoasService.patchObject<Accettazione>(url, accettazione);
  }

  createRendiconto(rendiconto) {
    const url = `${environment.endpointUri}/rendicontoes`;
    return this.hateoasService.createObject<Rendiconto>(url, rendiconto);
  }

  getServizioStruttura(idServizio: number, idStruttura: number) {
    const url = `${environment.endpointUri}/servizioStrutturas/search/findServizioStruttura?strutturaId=${idStruttura}&servizioId=${idServizio}`;
    return this.hateoasService.get<any>(url);
  }

  getClienteFromContratto(idContratto: number) {
    const url = `${environment.endpointUri}/contrattoes/${idContratto}/soggetto`;
    return this.hateoasService.get<Soggetto>(url);
  }

  patchRendiconto(objRendiconto, idRendiconto: number) {
    const url = `${environment.endpointUri}/rendicontoes/${idRendiconto}`;
    return this.hateoasService.patchObject<Rendiconto>(url, objRendiconto);
  }
  richiediTrasferimento(objRendiconto, idRendiconto: number) {
    const url = `${environment.endpointUri}/rendicontoes/search/richiediTrasferimento?idRendiconto=${idRendiconto}`;
    return this.hateoasService.updateObject<Rendiconto>(url, objRendiconto);
    
  }

  deleteRendiconto(id: number) {
    const url = `${environment.endpointUri}/rendicontoes/${id}`;
    return this.hateoasService.deleteObject(url);
  }
}
