import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-logout-popover',
  templateUrl: './logout-popover.component.html',
  styleUrls: ['./logout-popover.component.scss'],
})
export class LogoutPopoverComponent implements OnInit {

  pop : PopoverController;

  constructor(private navParams : NavParams) {
    this.pop = navParams.get('popoverController');
   }

  ngOnInit() {}

  disconnetti() {
    this.pop.dismiss({logout : true});
  }

  cambiaPassword() {
    this.pop.dismiss({cambiaPassword: true});
  }

}
