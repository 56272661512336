import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalController} from "@ionic/angular";
import {Costanti} from "../../../../core/models/costanti";
import {CommonService} from "../../../../core/services/common.service";
import {RendicontoInErogazione} from "../../../../operatori/models/rendiconto-erogazione";
import {Marca} from "../../../../core/models/marca";
import {debounceTime, filter, map} from "rxjs/operators";
import {TargaServiceService} from "../../../../operatori/home/services/targa-service.service";
import {Veicolo} from "../../../../operatori/models/veicolo";
import {UtilityService} from "../../../../core/services/utility.service";
import {AttivitaService} from "../../../../operatori/attivita/services/attivita.service";

@Component({
    selector: 'app-modale-modifica-veicolo',
    templateUrl: './modale-modifica-veicolo.component.html',
    styleUrls: ['./modale-modifica-veicolo.component.scss'],
})
export class ModaleModificaVeicoloComponent implements OnInit {

    veicoloForm = new FormGroup({});
    dimensioneList: Costanti[] = [];
    dimensioneSelezionata: Costanti = new Costanti();
    marcheList: Marca[] = [];
    selectedMarca = '';
    dimensioniVeicolo: any = [];

    rendiconto: RendicontoInErogazione = new RendicontoInErogazione();
    veicolo: Veicolo = new Veicolo();


    constructor(private modalController: ModalController,
                private commonService: CommonService,
                private targaService: TargaServiceService, private utilsService: UtilityService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    ionViewWillEnter() {
        this.getDimensioni();
        this.configureMarcheAuto();
    }

    initForm() {
        this.veicoloForm.addControl('targa', new FormControl(this.veicolo.targa, Validators.required));
        this.veicoloForm.addControl('marcaAuto', new FormControl(this.veicolo.marca, {validators: Validators.required}));
        this.veicoloForm.addControl('modello', new FormControl(this.veicolo.modello, Validators.required));
        this.veicoloForm.addControl('dimensione', new FormControl('', Validators.required));
    }

    getDimensioni() {
        this.commonService.get(this.veicolo._links.dimensioneVeicolo.href).subscribe( (dimensioneVeicolo: Costanti) => {
            this.commonService.getConstantsByType('DIMENSIONE_VEICOLO').subscribe(res => {
                this.dimensioneList = res._embedded.costantis;
                this.dimensioneSelezionata = this.dimensioneList.find( dimensioneObj => dimensioneObj.valore == dimensioneVeicolo.valore)

                // this.dimensioniVeicolo = res;
                // res._embedded.costantis.forEach((element: Costanti) => {
                //     this.dimensioneList.push(element);
                //     if (this.veicolo.dimensioneVeicolo && this.veicolo.dimensioneVeicolo.valore && element.valore === this.veicolo.dimensioneVeicolo.valore) {
                //         this.dimensioneSelezionata = element;
                //     }
                // });
                this.veicoloForm.get('dimensione').patchValue(this.dimensioneSelezionata);
            });
        })
    }

    configureMarcheAuto() {
        this.veicoloForm.get('marcaAuto').valueChanges.pipe(
            map((event: any) => {
                this.marcheList = [];
                return event;
            })
            , filter(res => res && res.length > 2)
            , debounceTime(800)
        ).subscribe((text: string) => {
            this.getListaMarche(text);
        });
    }

    getListaMarche(text) {
        this.targaService.getMarcaAuto(text, {
            meta: {
                isAutocomplete: true,
                nomeAutocomplete: "marcaSpinner"
            }
        }).subscribe(result => {
            this.marcheList = result._embedded.marcas;
        }, error => {
            console.log(error);
        });
    }

    chooseMarca(marca) {
        this.selectedMarca = marca;
        this.veicoloForm.get('marcaAuto').setValue(marca.marca, {emitEvent: false});
        this.marcheList = [];
    }

    findVeicoloByTarga() {
        const targa = this.veicoloForm.get('targa').value;
        if (targa.length <= 2) return;
        this.targaService.getVeicoloByTarga(targa.toUpperCase())
            .pipe(
                debounceTime(500)
            ).subscribe((res: any) => {
                this.veicolo = res;
                this.veicoloForm.get('marcaAuto').patchValue(res.marca, {emitEvent: false});
                this.veicoloForm.get('modello').patchValue(res.modello);
                const dimensione = this.dimensioneList.filter(element => element.valore === res.dimensioneVeicolo.valore)[0];
                this.veicoloForm.get('dimensione').setValue(dimensione);
            }, () => {
                const dimensione = this.dimensioneList.filter(element => element.valore === 'Medio')[0];
                this.veicoloForm.get('dimensione').setValue(dimensione);
                this.veicoloForm.get('marcaAuto').patchValue('');
                this.veicoloForm.get('modello').patchValue('');
            });
    }

    submitModify() {
        if (this.veicoloForm.invalid) {
            return;
        }
        const dimensione: Costanti = this.veicoloForm.get('dimensione').value;
        this.veicolo.targa = this.veicoloForm.get('targa').value.toUpperCase();
        this.veicolo.marca = this.veicoloForm.get('marcaAuto').value;
        this.veicolo.modello = this.veicoloForm.get('modello').value;
        // verifico se rendiconto esiste
        //TODO rendiconto togliere da local storage
        if(this.rendiconto){
            this.rendiconto.veicolo = this.veicolo;
            this.rendiconto.dimensione = dimensione.valore;
        }
        const veicoloObj = {
            targa: this.veicolo.targa,
            marca: this.veicolo.marca,
            modello: this.veicolo.modello,
            dimensioneVeicolo: dimensione._links.self.href
        };
        this.targaService.getVeicoloByTarga(veicoloObj.targa.toUpperCase()).subscribe(() => {
            const idVeicolo: number = this.utilsService.getIdBySelfHref(this.veicolo._links.veicolo.href.replace('{?projection}', ''));
            this.targaService.patchVeicolo(veicoloObj, idVeicolo).subscribe((res: any) => {
                this.modalController.dismiss({rendiconto: this.rendiconto, veicolo: res, dimensione: dimensione});
            }, (err) => {
                console.log(err);
            });
        }, (err) => {
            this.targaService.createVeicolo(veicoloObj).subscribe((res: any) => {
                this.modalController.dismiss({rendiconto: this.rendiconto, veicolo: res, dimensione: dimensione});
            }, err => {
                console.log(err);
            });
        });
    }

    dismiss() {
        this.modalController.dismiss({rendiconto: undefined, veicolo: undefined});
    }
}
