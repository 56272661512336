import { Hateoas } from './hateoas';
import { HrefLink, GetList } from '../interfaces/generic-links';

export class Veicolo extends Hateoas<VeicoloLinks> {
    targa: string;
    identificativo: number;
    marca: string;
    modello: string;
    isCompleto: boolean;
    cluster: string;
    clusterLink : string;
    accettazioAttivaId: string;
    accettazioPrenotazioneAttivaId: string;
    dimensioneVeicolo : any; //può essere stringa o oggetto
    tipoVeicolo : any;
    italiana : boolean;

    abbonamentoZtl : boolean;
    listaBianca : boolean;
    nomeUtilizzatore? : string;
    cognomeUtilizzatore? : string;
    telefonoUtilizzatore? : string;
    emailUtilizzatore? : string;

    //projection
    tipoVeicoloValore : string;
    dimensioneVeicoloValore : string;

    constructor(values: Veicolo = {} as Veicolo) {
        super(values);
    }
}

export interface VeicoloCaratteristiche {
    chiave : string;
    valore : string;
    search : string;
}

export interface VeicoloLinks {
    self: HrefLink;
    profile: HrefLink;
    search: HrefLink;
    dimensioneVeicolo : HrefLink;
}

export interface VeicoloList {
    veicoloes: Veicolo[];
}