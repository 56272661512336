import {Veicolo} from './veicolo';

export class RendicontoInErogazione {
    cliente: string;
    idRendiconto : number;
    clusterValore: string;
    codConvenzione: any;
    contrattoId: number;
    danni: boolean;
    dataFine: string;
    dataInizio: string;
    dimensione: string;
    dipendenteAnnullamento: any;
    dipendenteChiusura: any;
    dipendenteIncaricato: string;
    esisteAccettazione: boolean;
    fatturaEmessa: boolean;
    fatturaRichiesta: boolean;
    icon: string;
    importo: number;
    macroArea: string;
    modificabile: boolean;
    nomeServizio: string;
    pagato: boolean;
    stato: string;
    values: any;
    veicolo: Veicolo;
    veicoloId: number;
}
