import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from 'src/app/core/models/breadcrumb';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  @Input() listBreadCrumb: Breadcrumb[];

  constructor(private router: Router) { }

  ngOnInit() { }

  goToLink(link: Breadcrumb, last: boolean) {
    event.preventDefault();
    event.stopPropagation();
    if (last == false) {
      // let params: Params = link.queryParams;
      this.router.navigate([link.routerLink]);
    }
  }

  goToHome() {
    this.router.navigate(['responsabili']);
  }

}
