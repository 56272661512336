import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShUserNotifyService } from 'src/app/core/services/sh-user-notify.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { RendicontiService } from 'src/app/responsabili/services/rendiconti.service';
import { ModalConfermaComponent } from 'src/app/shared/responsabili/components/modal-conferma/modal-conferma.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-card-storico',
  templateUrl: './card-storico.component.html',
  styleUrls: ['./card-storico.component.scss'],
})
export class CardStoricoComponent implements OnInit {

  @Input() icon: string;
  //flotta
  @Input() tipo: string;
  @Input() isCompleto = false;
  @Input() inErogazione = false;

  //storico servizi
  @Input() statoRendiconto : string = '';
  @Input() title: string;
  @Input() labelColUno: string;
  @Input() labelColDue: string;
  @Input() labelColTre: string;
  @Input() valoreColUno: string;
  @Input() valoreColDue: string;
  @Input() valoreColTre: string;
  @Input() testoBadge: string;
  @Input() danni: boolean = false;
  @Input() closeIcon: boolean = false;
  @Input() otherInfo: string;
  @Input() note: boolean = false;
  @Input() flagTrasferimento: boolean ;
  @Input() rendicontoSelfHref : string;
  @Input() rendicontoHref : string;

  @Output() showDanni: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  color: string;
  titleOperatore: string;
  showBadge: boolean = false;
  badgeColor: string;
  

  constructor(private shUserNotifyService: ShUserNotifyService ,private rendicontiService: RendicontiService, private utilityService: UtilityService,public dialog: MatDialog) { 
    
  }

  ngOnInit() {

    //FLOTTA
    if (this.tipo == 'Flotta') {
      this.icon = "H";
      this.color = "inInserimento";
      this.badgeColor = "badgeClassico";
      this.showBadge = true;
      if (this.testoBadge == 'daConfermare') {

        this.color = 'daConfermare';
        this.badgeColor = 'badgeDaConfermare'
      } else if (this.testoBadge == 'rifiutata') {
        this.badgeColor = 'badgeRifiutata';
        this.color = 'rifiutata';

      } else if (this.testoBadge == 'confermata') {
        this.badgeColor = 'badgeApprovata';
        this.color = 'approvata';
      } else if (this.testoBadge == 'lavorata') {
        this.badgeColor = 'badgeLavorata';
        this.color = 'lavorata';
      }

      //STORICO 
    } else if (this.tipo == 'Storico') {
        //se stato annullato, card in rosso
        if (this.statoRendiconto == 'annullato') {
          this.color = "rendicontoAnnullato";
        }
        else {
          this.color = "colorBgServizi";
        }

      
    }    
    //INVENTARIO
    else if (this.tipo == 'Inventario') {
      //this.showBadge = true;

      //if (this.testoBadge == "aperto") {
      //   this.badgeColor = 'badgeDaConfermare';
      //} else {
      //  this.badgeColor = 'badgeApprovata';
      //}
      //this.badgeColor = 'badgeDaConfermare';
      this.icon = "I";
      this.color = "colorBgInventario";

    }
    else {
      //CASO DI ATTIVITA
      this.color = this.tipo;
    }

  }

  danniClick(event: Event) {
    event.stopPropagation();
    this.showDanni.emit(true);
  }

  trasferisci(event: Event) {

    event.stopPropagation();

    const dialogRef = this.dialog.open(ModalConfermaComponent, {
      width: '400px',
      data: {messaggio : 'Richiedere trasferimento?'}
    });
    dialogRef.afterClosed().subscribe(result => {
    
      if (result) {
        console.log(this.rendicontoSelfHref)
        this.rendicontiService.richiediTrasferimento({flagTrasferimento : !this.flagTrasferimento}, this.utilityService.getIdBySelfHref(this.rendicontoSelfHref))
       .subscribe( result => {
         this.shUserNotifyService.notifySuccess("Richiesta di trasferimento avvenuta con successo");
         this.flagTrasferimento = !this.flagTrasferimento;
         this.refresh.emit(true);
        },
        error => {
          this.shUserNotifyService.notifyError(error.error.message);
        });
      }
    });

  }



}
