import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse, HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShUserNotifyService } from './sh-user-notify.service';
import { LoadingController } from '@ionic/angular';
 
@Injectable()
export class CustomInterceptorService implements HttpInterceptor {

  loaderToShow : any;
  count : number = 0;
  
  constructor(private router: Router, private shUserNotifyService: ShUserNotifyService,
              public loadingCtrl : LoadingController) { }
 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Non mostrare loader se è una chiamata per autocomplete
    let isAutocomplete: boolean = false;
    let nomeAutocomplete: string = '';
    if (request.params.get('meta')) {
      isAutocomplete = (request.params.get('meta') as any).isAutocomplete;
      nomeAutocomplete = (request.params.get('meta') as any).nomeAutocomplete;
    }
    if (!isAutocomplete) {
      this.count = this.count +1;
      document.getElementById('loader').classList.remove('hide');
      document.body.style.height = "100vh";
      document.body.style.overflowY = "hidden";
      document.body.scrollTop = 0;
    }
    else {
      document.getElementById(nomeAutocomplete).style.display = 'block';
      
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!isAutocomplete) {
            this.count = this.count -1;
            if (this.count == 0) {
              document.getElementById('loader').classList.add('hide');
              document.body.style.height = "";
              document.body.style.overflowY = "";
            }
          }
          else {
            document.getElementById(nomeAutocomplete).style.display= 'none';
          }
          if (request.url.includes("principalImpls/resetPasswordRequest")) {
            this.shUserNotifyService.notifySuccess("Richiesta di reset della password inviata");
            this.router.navigate(['security/login']);
          }
          
         }
         //chiamata della libreria richiesta reset password
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.count = this.count -1;
        if (this.count == 0) {
          document.getElementById('loader').classList.add('hide');
          document.body.style.height = "";
          document.body.style.overflowY = "";
        }
        if (error.status == 401) {
          this.shUserNotifyService.notifyWarning("Credenziali errate.");
          localStorage.clear();
          this.router.navigate(['security/login']);
        }else if (error.status !== 404) {
          this.shUserNotifyService.notifyError();
        }
        return throwError(error);
      })
      );
  }

}
