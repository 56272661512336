import {Component, Input, OnInit} from '@angular/core';
import {HateoasService} from 'src/app/core/services/hateoas.service';
import {environment} from 'src/environments/environment';
import {ModalController, Platform} from '@ionic/angular';
import {UtilityService} from 'src/app/core/services/utility.service';
import {Printer} from '@ionic-native/printer/ngx';
import {ShUserNotifyService} from 'src/app/core/services/sh-user-notify.service';
import {TitleCasePipe} from '@angular/common';

const REGEX_FORMAT = new RegExp(/_.+/);

@Component({
    selector: 'app-modale-qr-code',
    templateUrl: './modale-qr-code.component.html',
    styleUrls: ['./modale-qr-code.component.scss'],
})
export class ModaleQrCodeComponent implements OnInit {

    @Input() idAccettazione: number;
    @Input() rendiconto: any;
    @Input() rendicontoId: number;

    //oggetto con i dati del qr code tornato da BE
    qrCodeObj = {
        qrcode: '',
        testo: '',
        garage: '',
        veicolo: {} as any,
        danni: {},
        orarioIngresso: "",
        indirizzoStruttura: {} as any,
        telefonoStruttura: "",
        convenzione: false,
        numeroStanza: "",
        nomeCliente: "",
        numeroRicevuta: "",
        nomeHotel: null,
    };

    //qr code
    qrCreato = null;

    constructor(private hateoasService: HateoasService, private modalController: ModalController,
                private utilityService: UtilityService, private printer: Printer,
                private platform: Platform, private shUserNotifyService: ShUserNotifyService,
                private titlecasePipe: TitleCasePipe) {
    }

    ngOnInit() {
        this.getQrCode();
    }

    getQrCode() {
        let url;
        if (this.rendicontoId) {
            url = `${environment.endpointUri}/ricevuta/search/getQRCode?accettazioneID=${this.idAccettazione}&rendicontoID=${this.rendicontoId}`;
        } else {
            url = `${environment.endpointUri}/ricevuta/search/getQRCode?accettazioneID=${this.idAccettazione}&rendicontoID=${this.utilityService.getIdBySelfHref(this.rendiconto._links.self.href)}`;
        }
        this.hateoasService.get<any>(url).subscribe(result => {
                this.qrCodeObj = result;
                this.qrCreato = result.testo;
            },
            error => {
                console.log('error qr code', error);
            });
    }

    stampa() {
        let content = document.getElementById('contenuto').innerHTML;
        var headNode = document.createElement("head");
        var styleNode = document.createElement("style");
        styleNode.innerHTML = "\
            img { width: 200px; }\
            .hide-when-printing { display: none; }";
        headNode.appendChild(styleNode);
        content = headNode.outerHTML + content.toString();
        if (this.platform.is("desktop")) {
            this.desktopPrint(content);
        } else {
            this.mobilePrint(content);
        }
    }

    desktopPrint(content) {
        let popup = window.open();
        popup.document.write(content);
        popup.document.close();
        popup.focus();
        popup.print();
        popup.close();
    }

    mobilePrint(content) {
        this.printer.isAvailable().then(connesso => {
                //ritorna un boolean del success
                console.log('success', connesso);
                this.printer.print(content).then(stampando => {
                        //success della stampa
                        console.log('stampando...', stampando);

                        //this.shUserNotifyService.notifySuccess("Stampa avvenuta con successo");
                    },
                    errorPrint => {
                        //fail della stampa
                        this.shUserNotifyService.notifyError(errorPrint);
                    });
            },
            errorAvailable => {
                //ritorna la ragione del regetto
                this.shUserNotifyService.notifyError(errorAvailable);
            });
    }

    chiudiModale() {
        this.modalController.dismiss();
    }

    formattaDanno(text: string): string {
        return text.replace(REGEX_FORMAT, "");
    }


}
