import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ShUserNotifyService } from 'src/app/core/services/sh-user-notify.service';
import { Subscription } from 'rxjs';
import {Camera, CameraOptions} from "@ionic-native/camera/ngx";
import {TargaServiceService} from "../../../../operatori/home/services/targa-service.service";
import {UtilityService} from "../../../../core/services/utility.service";
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-lista-targhe',
  templateUrl: './lista-targhe.component.html',
  styleUrls: ['./lista-targhe.component.scss'],
})
export class ListaTargheComponent implements OnInit, OnDestroy {

  targheInserite : string[] = [];
  kilometriArray : any[] = [];

  formTarghe = new FormGroup({});

  @Input() elencoTarghe : string;
  @Input() isFlotta : boolean = false;

  @Output() listaAggiornata : EventEmitter<string[]> = new EventEmitter();
  @Output() kmAggiornata : EventEmitter<any[]> = new EventEmitter();

  formSubscriptions : Subscription = new Subscription();
  enableScansionaTarga: boolean =false;
  constructor(private shUserNotifyService: ShUserNotifyService, private camera: Camera,
              private targaService: TargaServiceService, private utilityService: UtilityService,
              public platform: Platform,
  ) { }

  ngOnInit() {
    this.formTarghe.addControl('targa', new FormControl('', [Validators.required]));
    if (this.isFlotta) {
      this.formTarghe.addControl('km', new FormControl('', Validators.required));
    }
    //forzo tutto ad uppercase
    this.formSubscriptions = this.formTarghe.get('targa').valueChanges.subscribe( result => {
      let text = result.toLocaleUpperCase();
      this.formTarghe.get('targa').setValue(text, {emitEvent: false});

    });

    //se elenco targhe diverso da undefined, siamo in modifica
    if (this.elencoTarghe) {
      if (this.isFlotta) {
        this.recuperaTargheInseriteFlotta();
      }
      else {
        this.recuperaTargheInseriteInventario();
      }
    }
  }

  addTarga() {
    //check se non è stata già inserita
    let index = this.targheInserite.findIndex(x => x == this.formTarghe.get('targa').value);
    if (index != -1) {
      //targa trovata
      this.shUserNotifyService.notifyError('Targa già aggiunta!');
    }
    else {
      this.targheInserite.push(this.formTarghe.get('targa').value);
      // EMIT NUOVA LISTA
      this.listaAggiornata.emit(this.targheInserite);
      if (this.isFlotta) {
        this.kilometriArray.push(this.formTarghe.get('km').value);
        this.kmAggiornata.emit(this.kilometriArray);
      }
    }
    this.formTarghe.get('targa').setValue('');
    if (this.isFlotta) {
      this.formTarghe.get('km').setValue('');
    }
  }

  removeTarga(targa) {
    //cerco la targa e la rimuovo dalla lista
    let index = this.targheInserite.findIndex(x => x == targa);
    this.targheInserite.splice(index, 1);
    // EMIT LISTA UPDATED
    this.listaAggiornata.emit(this.targheInserite);
    if (this.isFlotta) {
      this.kilometriArray.splice(index, 1);
      this.kmAggiornata.emit(this.kilometriArray);
    }
  }

  recuperaTargheInseriteInventario() {
    let array = this.elencoTarghe.split(';');
    //l'ultimo è una stringa vuota
    array.splice(array.length -1);
    this.targheInserite = array;
    this.listaAggiornata.emit(this.targheInserite);
  }

  recuperaTargheInseriteFlotta() {
    for (let [key, value] of Object.entries(this.elencoTarghe)) {
      this.targheInserite.push(key);
      this.kilometriArray.push(parseInt(value).toLocaleString());
    }
    this.listaAggiornata.emit(this.targheInserite);
    this.kmAggiornata.emit(this.kilometriArray);
  }

  scansionaTarga(){
    this.enableScansionaTarga = true;
  }
  targaEvent(event: string) {
    this.formTarghe.get('targa').setValue(event);
    this.enableScansionaTarga = false;
  }

  scansionaTargaOld() {
    const options: CameraOptions = {
      quality: 80,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    this.camera.getPicture(options).then(result => {
      const formData: FormData = new FormData();
      const blob = this.utilityService.dataURItoBlob(result);
      const file = new File([blob], 'file.jpg', { type: 'image/png' });
      formData.append('file', file);
      this.targaService.readPlateForRecognition(formData).subscribe(res => {
        this.formTarghe.get('targa').setValue(res);
      }, err => {
        const error = JSON.parse(err.error);
        console.log('error', error);
        this.shUserNotifyService.notifyError(error.message);
      });
    }, error => console.log('error', error));
  }

  ngOnDestroy() {
    this.formSubscriptions.unsubscribe();
  }


}
