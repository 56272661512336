import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PrincipalService } from 'dst-security';
import { ShUserNotifyService } from 'src/app/core/services/sh-user-notify.service';
import { TargaServiceService } from 'src/app/operatori/home/services/targa-service.service';

@Component({
  selector: 'app-modal-conferma-password',
  templateUrl: './modal-conferma-password.component.html',
  styleUrls: ['./modal-conferma-password.component.scss'],
})
export class ModalConfermaPasswordComponent implements OnInit {

  passwordControl: FormControl;
  isTextFieldType: boolean = false;
  type: string = "password";
  icon: string = "eye";

  constructor(private modalCtrl: ModalController, private targaService: TargaServiceService,
              private shUserNotifyService: ShUserNotifyService) { }

  ngOnInit() {
    this.passwordControl = new FormControl('', Validators.required);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  confermaPassword() {
    this.targaService.verificaPassword(this.passwordControl.value).subscribe( result => {
      if (result.succes) {
        this.shUserNotifyService.notifySuccess(result.messaggio);
        this.modalCtrl.dismiss(true);
      }
      else {
        this.shUserNotifyService.notifyError(result.messaggio);
      }
    },
    error => {
      console.log('error verifica pass', error);
    });
  
  }

  changeType() {
    setTimeout( () => {
      this.isTextFieldType = !this.isTextFieldType;
      if (this.isTextFieldType) {
        this.type = "text";
      }
      else {
        this.type = "password";
      }
    }, 100);
  }
}
