import { Directive, OnInit, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appChilometraggioPipe]'
})
export class ChilometraggioPipeDirective implements OnInit{

  element : HTMLInputElement;

  constructor(private elementRef : ElementRef) {
    this.element = elementRef.nativeElement;
  }

  ngOnInit() {
    //se il campo input è già valorizzato, questo viene già formattato
    if (this.element.value) {
      this.element.value = parseInt(this.element.value).toLocaleString();
    }
  }  

  @HostListener('ionFocus')
  @HostListener('focus')
  onFocus() {
    //al focus il valore torna numerico
    if (this.element.value) {
      this.element.value = this.element.value.toString().replace(/\.|,/g, '');
    }
  } 

  @HostListener('ionBlur') 
  @HostListener('blur') 
  onBlur() {
    //formatto il valore con il punto ai decimali
    if (this.element.value) {
      this.element.value = parseInt(this.element.value).toLocaleString();
    }
  }

  @HostListener('keyup')
  onKeyUp() {
    let match = this.element.value.match(/\d+/g);
    if (match) {
      this.element.value = match.join("");
    }
    else {
      console.log('no match');
      this.element.value = "";
    }
    //this.element.value = this.element.value.toString().replace(/\.|,/g, '');

    //se il valore non è un numero, cambio il valore dell'input con ste stesso meno l'ultimo carattere digitato
    //if (this.element.value && isNaN(+this.element.value)) {
    //  this.element.value = this.element.value.substring(0, this.element.value.length - 1);
    //}
  }

}
