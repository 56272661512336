import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Rendiconto } from 'src/app/core/models/rendiconto';

@Component({
  selector: 'app-modale-gestisci-chiudi-servizio',
  templateUrl: './modale-gestisci-chiudi-servizio.component.html',
  styleUrls: ['./modale-gestisci-chiudi-servizio.component.scss'],
})
export class ModaleGestisciChiudiServizioComponent implements OnInit {

  @Input() rendiconto;
  @Input() labelBottoneUno: string;
  @Input() labelBottoneDue: string;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }

  close(data?) {
    this.modalCtrl.dismiss(data);
  }

}
