import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserNotifyService } from 'dst-security';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class ShUserNotifyService extends UserNotifyService {
  static snackBar: MatSnackBar;
  notify = UtilityService.showMessage;

  constructor(public snackBar: MatSnackBar) {
    super();
  }

  notifySuccess(message) {
    message = message ? message : 'Success';
    this.notify(message, 'Success');
  }

  notifyInfo(message) {
    message = message ? message : 'Info';
    this.notify(message, 'Info');
  }

  notifyWarning(message) {
    message = message ? message : 'Warning';
    this.notify(message, 'Warn');
  }

  notifyError(message?) {
    message = message ? message : "Si è verificato un errore.";
    this.notify(message, 'Error');
  }
}
