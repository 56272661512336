import {Hateoas} from "../../core/models/hateoas";
import {VeicoloLinks} from "../../responsabili/models/veicolo";

export class Veicolo extends Hateoas<VeicoloLinks> {
    cognomeUtilizzatore: string;
    emailUtilizzatore: string;
    identificativo: string;
    isCompleto: boolean;
    italiana: string;
    listaBianca: boolean;
    marca: string;
    modello: string;
    nomeUtilizzatore: string;
    targa: string;
    telefonoUtilizzatore: number;
    dimensioneVeicolo: any;
}
