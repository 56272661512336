import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {CameraPreview} from "@ionic-native/camera-preview/ngx";
import {UtilityService} from "../../core/services/utility.service";
import {TargaServiceService} from "../../operatori/home/services/targa-service.service";
import {ShUserNotifyService} from "../../core/services/sh-user-notify.service";
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-scansiona-targa',
    templateUrl: './scansiona-targa.component.html',
    styleUrls: ['./scansiona-targa.component.scss'],
})
export class ScansionaTargaComponent implements OnInit, OnDestroy {

    @Output() targaEvent = new EventEmitter<string>();
    myBase64: any;
    IMAGE_PATH: string;

    constructor(
        private modalCtrl: ModalController,
        private cameraPreview: CameraPreview,
        private utilityService: UtilityService,
        private targaService: TargaServiceService,
        private shUserNotifyService: ShUserNotifyService,
    ) {
    }

    ngOnInit() {
        this.startCameraBelow();
    }

    ngOnDestroy(): void {
        this.cameraPreview.stopCamera();
    }

    ionViewDidEnter() {
        this.startCameraBelow();
    }

    ionViewWillLeave() {
        this.cameraPreview.stopCamera();
    }


    startCameraBelow() {
        this.cameraPreview.startCamera({
            x: 0,
            y: 80,
            width: window.screen.width,
            height: 420,
            toBack: true,
            previewDrag: false,
            tapPhoto: true,
            camera: 'rear'
        });
    }

    takePicture() {
        this.cameraPreview.takePicture({
            width: window.screen.width,
            height: 420,
            quality: 85
        }).then((imageData) => {
            this.myBase64 = imageData;
            this.IMAGE_PATH = 'data:image/jpeg;base64,' + imageData;
            const formData: FormData = new FormData();
            const blob = this.utilityService.dataURItoBlob(this.myBase64);
            const file = new File([blob], 'file.jpg', {type: 'image/png'});
            formData.append('file', file);
            this.targaService.readPlateForRecognition(formData).subscribe(response => {
                this.targaEvent.emit(response);
                return this.modalCtrl.dismiss(response, 'confirm');
                // this.fastCheckInForm.get('targa').setValue(response, {emitEvent: false});

            }, err => {
                const error = JSON.parse(err.error);
                console.log('error', error);
                this.shUserNotifyService.notifyError(error.message);
            });
        }, (err) => {
            console.log(err);
            this.IMAGE_PATH = 'assets/img/test.jpg';
        });
    }


    cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }

    confirm() {
        // return this.modalCtrl.dismiss(this.name, 'confirm');
    }


}
