import { Injectable } from '@angular/core';
import { RuoliCostanti } from '../models/ruoli-costanti';
import { HateoasService } from './hateoas.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { Dipendente } from 'src/app/responsabili/models/dipendente';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  funzionalita = new RuoliCostanti();

  public appPages = [
    //{
    //  title: 'Gestione utenti',
    //  url: '/responsabili',
    //  icon: 'people',
    //  enabled: false
    //},
    {
      title: 'Anagrafiche',
      icon: 'folder_shared',
      enabled: this.checkRole(this.funzionalita.STRUTTURA_VIEW) || this.checkRole(this.funzionalita.DIPENDENTE_VIEW) || this.checkRole(this.funzionalita.CLIENTE_VIEW),
      children: [
        {
          title: 'Strutture',
          url: '/responsabili/anagrafiche',
          enabled: this.checkRole(this.funzionalita.STRUTTURA_VIEW),
        },
        {
          title: 'Clienti',
          url: '/responsabili/anagrafiche/clienti',
          enabled: this.checkRole(this.funzionalita.CLIENTE_VIEW),
        },
        {
          title: 'Dipendenti',
          url: '/responsabili/dipendenti',
          enabled: this.checkRole(this.funzionalita.DIPENDENTE_VIEW),
        }
      ]
    },
    {
      title: 'Articoli',
      url: '/responsabili/articoli',
      icon: 'inbox',
      enabled: this.checkRole(this.funzionalita.ARTICOLO_VIEW),
    },
    {
      title: 'Servizi',
      url: '/responsabili/servizi',
      icon: 'ballot',
      enabled: this.checkRole(this.funzionalita.SERVIZIO_VIEW),
    },
    {
      title: 'Tariffe',
      url: '/responsabili/tariffe',
      icon: 'list_alt',
      enabled: this.checkRole(this.funzionalita.TARIFFA_VIEW),
    },
    {
      title: 'Marche & modelli',
      url: '/responsabili/marche-modelli',
      icon: 'menu_book',
      enabled: this.checkRole(this.funzionalita.MARCA_MODELLO_VIEW),
    },
    {
      title: 'Gestione veicoli',
      url: '/responsabili/gestione-veicoli',
      icon: 'directions_car',
      enabled: this.checkRole(this.funzionalita.VEICOLO_VIEW),
    },
    {
      title: 'Contratti',
      url: '/responsabili/contratti',
      icon: 'assignment',
      enabled: this.checkRole(this.funzionalita.CONTRATTO_VIEW),
    },
    {
      title: 'Foglio firma',
      url: '/responsabili/foglio-firma',
      icon: 'rate_review',
      enabled: this.checkRole(this.funzionalita.FOGLIO_FIRMA),
    },
    {
      title: 'Gestione trasferimenti',
      url: '/responsabili/gestione-trasferimenti',
      icon: 'place',
      enabled: this.checkRole(this.funzionalita.GESTIONE_TRASFERIMENTI_VIEW),
    },
    {
      title: 'Situazione economica',
      url: '/responsabili/situazione-economica',
      icon: 'euro',
      enabled: this.checkRole(this.funzionalita.SITUAZIONE_ECONOMICA_VIEW),
    },
    {
      title: 'Prenotazioni',
      url: '/responsabili/prenotazioni',
      icon: 'business_center',
      enabled: this.checkRole(this.funzionalita.PRENOTAZIONI_VIEW),
    },
    {
      title: 'Flusso Dylog',
      url: '/responsabili/flusso-dylog',
      icon: 'poll',
      enabled: this.checkRole(this.funzionalita.FLUSSO_DYLOG_VIEW),
    },
    {
      title: 'Gestione ZTL',
      url: '/responsabili/gestione-ztl',
      icon: 'transit_enterexit',
      enabled: this.checkRole(this.funzionalita.ZTL),
    },
    {
      title: 'Report',
      url: '/responsabili/report',
      icon: 'receipt',
      enabled: this.checkRole(this.funzionalita.REPORT),
    },
    {
      title: 'Gestione Flotta',
      url: '/responsabili/gestione-flotta',
      icon: 'toc',
      enabled: this.checkRole(this.funzionalita.APPROVAZIONE_FLOTTA),
    },
    {
      title: 'Gestione Rendiconti',
      url: '/responsabili/gestione-rendiconti',
      icon: 'receipt_long',
      enabled: false
    },
    {
      title: 'Configurazioni',
      url: '/responsabili/configurazioni',
      icon: 'settings',
      //enabled: false
       enabled: this.checkRole("superadmin")
    } ];

  constructor(private hateoasService : HateoasService, private utilityService : UtilityService) { }



  //torna l'utente loggato
  getUserLogged() {
    if (localStorage.getItem('user') != null) {
      let user =  JSON.parse(localStorage.getItem('user'));
      let url = `${environment.endpointUri}/dipendentes/search/findByMail?email=${user.email}`;
      return this.hateoasService.get<Dipendente>(url);
    }
  }

  //controllo in base al ruolo dell'utente se può fare accedere ad una determinata funzione o no
  checkRole(azione) {
    if (localStorage.getItem('user') != null) {
      let user = JSON.parse(localStorage.getItem('user'));
      //se super admin o manager return true
      if (user.profile.name == 'superadmin' || user.profile.name == 'manager') {
        return true;
      }
      //altrimenti controllare tra i sys roles
      else {
        let index = user.profile.sysRoles.findIndex( x => x.name == azione);
        if (index != -1) {
          //può effettuare quell'operazione;
          return true;
        }
        else {
          //non abilitato
          return false;
        }
      }
    }
  }

}
