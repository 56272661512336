import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-elimina-popover',
  templateUrl: './elimina-popover.component.html',
  styleUrls: ['./elimina-popover.component.scss'],
})
export class EliminaPopoverComponent implements OnInit {

  confermaEliminazione : boolean = false;

  pop : PopoverController;

  constructor(navParams: NavParams) { 
    this.pop = navParams.get('popoverController');
  }

  ngOnInit() {}

  elimina() {
    this.confermaEliminazione = true;
  }

  annulla() {
    this.pop.dismiss();
  }

  cancella() {
    this.pop.dismiss({conferma : true});
  }
}
