import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HateoasService } from 'src/app/core/services/hateoas.service';
import { ShUserNotifyService } from 'src/app/core/services/sh-user-notify.service';
import { environment } from 'src/environments/environment';

const regexPw = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{8,}$/);

@Component({
  selector: 'app-modale-cambia-password',
  templateUrl: './modale-cambia-password.component.html',
  styleUrls: ['./modale-cambia-password.component.scss'],
})
export class ModaleCambiaPasswordComponent implements OnInit {


  form: FormGroup = new FormGroup({});
  hideoldPassword: boolean = true;
  hidenewPassword: boolean = true;
  hideconfermaPassword: boolean = true;

  constructor(private modalCtrl: ModalController, private hateoasService: HateoasService,
              private shUserNotifyService: ShUserNotifyService) { }

  ngOnInit() {
    this.form.addControl('oldPassword', new FormControl('', [Validators.required]));
    this.form.addControl('newPassword', new FormControl('', [Validators.required, Validators.pattern(regexPw)]));
    this.form.addControl('confermaPassword', new FormControl('', [Validators.required, Validators.pattern(regexPw)]));
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  cambiaPassword() {
    const url = `${environment.endpointUri}/dipendentes/search/cambiaPassword?oldPassword=${this.form.get("oldPassword").value}&newPassword=${this.form.get("newPassword").value}`;
    this.hateoasService.get<any>(url).subscribe( res => {
      this.shUserNotifyService.notifySuccess("Password cambiata con successo");
      this.dismiss();
    },
    error => {
      this.shUserNotifyService.notifyError(error.error.message);
    });
  }

}
