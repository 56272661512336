import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modale-cancellazione-elemento',
  templateUrl: './modale-cancellazione-elemento.component.html',
  styleUrls: ['./modale-cancellazione-elemento.component.scss'],
})
export class ModaleCancellazioneElementoComponent implements OnInit {

  deleteForm = new FormGroup({});
  servizio : string = '';

  constructor(private modalController: ModalController, private navParas : NavParams) {
    this.servizio = this.navParas.get('macroarea');
   }

  ngOnInit() {
    this.deleteForm.addControl('motivo', new FormControl('', Validators.required));
  }

  annulla() {
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }

  conferma() {
    this.modalController.dismiss(this.deleteForm.get('motivo').value);
  }
}
