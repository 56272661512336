import { Hateoas } from './hateoas';
import { HrefLink, GetList } from '../interfaces/generic-links';

export class Costanti extends Hateoas<CostantiLinks> {
    chiave: string;
    valore: string;
    descrizione: string;
    sorting: number;

    constructor(values: Costanti = {} as Costanti) {
        super(values);
    }
}

export interface CostantiLinks {
    self: HrefLink;
    costanti: HrefLink;
}

export interface CostantiList {
    costantis: Costanti[];
}