import { Injectable } from '@angular/core';
import { HateoasService } from 'src/app/core/services/hateoas.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { CostantiList, Costanti } from 'src/app/core/models/costanti';
import { GetList } from 'src/app/core/interfaces/generic-links';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends HateoasService {

  constructor(http: HttpClient, private hateoasService: HateoasService) {
    super(http);
  }

  getConstantsByType(type: string) {
    //FIND COSTANTI BY TYPE
    const url = `${environment.endpointUri}/costantis/search/findByChiave?chiave=${type}`;
    return this.hateoasService.get<GetList<CostantiList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getConstantsByStructAndCluster(cluster: string, strutturaId : number, dimensioneVeicolo : string, idContratto: number = 0) {
    //FIND COSTANTI BY STRUTTURA AND CLUSTER
    const url = `${environment.endpointUri}/costantis/search/findByStrutturaAndCluster?strutturaId=${strutturaId}&cluster=${cluster}&dimensione=${dimensioneVeicolo}&idContratto=${idContratto}`;
    return this.hateoasService.get<GetList<CostantiList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getCostantsByVeicoloAndStructAndContract(strutturaId : number, veicoloId : number, contrattoId : any) {
    //FIND ACTIVE SERVICES BY VEICOLO, STRUTTURA AND CONTRATTO IDs
    const url = `${environment.endpointUri}/costantis/search/findAttivi?strutturaId=${strutturaId}&veicoloId=${veicoloId}&contrattoId=${contrattoId}`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
    }

    getSenzaContratto(strutturaId : number, veicoloId : number, contrattoId : any) {
      const url = `${environment.endpointUri}/servizioes/search/getSottoServiziAttivi?strutturaId=${strutturaId}&veicoloId=${veicoloId}&contrattoId=${contrattoId}`;
      return this.hateoasService.get<any>(url).pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return Observable.throw(error);
        })
      );
    }
  
    getCostantsById(id : number) {
      const url = `${environment.endpointUri}/costantis/${id}`;
      return this.hateoasService.get<Costanti>(url).pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return Observable.throw(error);
        })
      );
    }

    getVersione() {
      const url = `${environment.endpointUri}/gestione/search/getVersione`;
      return this.hateoasService.get<{link: string, version: number, mandatory: boolean}>(url);
    }

    //getUserLogged() {
    //  //recupero il dipendente loggato
    //  //DA SISTEMARE
    //  const url = `${environment.endpointUri}/dipendentes/70`;
    //  return this.hateoasService.get<any>(url).pipe(
    //    map(response => {
    //      return response;
    //    }),
    //    catchError(error => {
    //      return Observable.throw(error);
    //    })
    //  );
    //}
}
