import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { CardStoricoComponent } from './operatori/components/card-storico/card-storico.component';
import { IonicModule } from '@ionic/angular';
import { ModaleRiepilogoInventarioComponent } from './operatori/components/modale-riepilogo-inventario/modale-riepilogo-inventario.component';
import { CardAttivitaComponent } from './operatori/components/card-attivita/card-attivita.component';
import { ModaleCancellazioneElementoComponent } from './operatori/components/modale-cancellazione-elemento/modale-cancellazione-elemento.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModaleElementoPendingComponent } from './operatori/components/modale-elemento-pending/modale-elemento-pending.component';
import { ModaleCampoObbligatorioComponent } from './operatori/components/modale-campo-obbligatorio/modale-campo-obbligatorio.component';
import { CardNotificationsComponent } from './operatori/components/card-notifications/card-notifications.component';
import { ChilometraggioPipeDirective } from './directives/chilometraggio-pipe.directive';
import { FocusColorDirective } from './directives/focus-color.directive';
import { CardListaServiziComponent } from './operatori/components/card-lista-servizi/card-lista-servizi.component';
import { TableComponent } from './responsabili/components/table/table.component';
import { MatButtonModule, MatSortModule, MatPaginatorModule, MatTableModule, MatProgressSpinnerModule, MatTooltipModule, MatFormFieldModule } from '@angular/material';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ModaleRegistrazioneDannoComponent } from './operatori/components/modale-registrazione-danno/modale-registrazione-danno.component';
import { ModalConfermaComponent } from './responsabili/components/modal-conferma/modal-conferma.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { ModaleQrCodeComponent } from './operatori/components/modale-qr-code/modale-qr-code.component';
import { EliminaPopoverComponent } from './operatori/components/elimina-popover/elimina-popover.component';
import { SafePipe } from './pipes/safe.pipe';
import { ListaTargheComponent } from './operatori/components/lista-targhe/lista-targhe.component';
import { LogoutPopoverComponent } from './operatori/components/logout-popover/logout-popover.component';
import { ModaleTargaSostitutivaComponent } from './operatori/components/modale-targa-sostitutiva/modale-targa-sostitutiva.component';
import { CurrencyFieldDirective } from './directives/currency-field.directive';
import { ModalConfermaPasswordComponent } from './operatori/components/modal-conferma-password/modal-conferma-password.component';
import { ConfermaAnnullaModalComponent } from './operatori/components/conferma-annulla-modal/conferma-annulla-modal.component';
import { ModaleGestisciChiudiServizioComponent } from './operatori/components/modale-gestisci-chiudi-servizio/modale-gestisci-chiudi-servizio.component';
import { ModaleCambiaPasswordComponent } from './operatori/components/modale-cambia-password/modale-cambia-password.component';
import {ModaleModificaVeicoloComponent} from "./operatori/components/modale-modifica-veicolo/modale-modifica-veicolo.component";
import {ScansionaTargaComponent} from "./scansiona-targa/scansiona-targa.component";
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  declarations: [
    CardAttivitaComponent,
    CardNotificationsComponent,
    CardStoricoComponent, ModaleCampoObbligatorioComponent, ModaleCancellazioneElementoComponent, ModaleElementoPendingComponent, ModaleRiepilogoInventarioComponent,
    CardListaServiziComponent,
    AutocompleteComponent,
    BreadcrumbComponent,
    ModaleQrCodeComponent,
    EliminaPopoverComponent,
    ListaTargheComponent,
    LogoutPopoverComponent,
    ModaleTargaSostitutivaComponent,
    ModalConfermaPasswordComponent,
    ConfermaAnnullaModalComponent,
    ModaleGestisciChiudiServizioComponent,
    ModaleCambiaPasswordComponent,
    //responsabili
    TableComponent,
    // direttive
    ChilometraggioPipeDirective,
    FocusColorDirective,
    ModaleRegistrazioneDannoComponent,
    ModalConfermaComponent,
    SafePipe,
    CurrencyFieldDirective,
    ModaleModificaVeicoloComponent,
    ScansionaTargaComponent,
    AutofocusDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    NgxQRCodeModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatFormFieldModule
  ],

  entryComponents: [
    ModaleRiepilogoInventarioComponent,
    ModaleRegistrazioneDannoComponent,
    ModalConfermaComponent,
    ModaleElementoPendingComponent,
    ModaleQrCodeComponent,
    ModaleCancellazioneElementoComponent,
    ModaleCampoObbligatorioComponent,
    EliminaPopoverComponent,
    LogoutPopoverComponent,
    ModaleTargaSostitutivaComponent,
    ModalConfermaPasswordComponent,
    ConfermaAnnullaModalComponent,
    ModaleGestisciChiudiServizioComponent,
    ModaleCambiaPasswordComponent,
    ModaleModificaVeicoloComponent,

  ],
  exports: [
    CardAttivitaComponent,
    CardNotificationsComponent,
    CardStoricoComponent, ModaleCampoObbligatorioComponent,
    ModaleCancellazioneElementoComponent, ModaleElementoPendingComponent, ModaleRiepilogoInventarioComponent,
    CardListaServiziComponent,
    AutocompleteComponent,
    BreadcrumbComponent,
    ModaleRegistrazioneDannoComponent,
    ModalConfermaComponent,
    EliminaPopoverComponent,
    ListaTargheComponent,
    LogoutPopoverComponent,
    ModaleTargaSostitutivaComponent,
    ModalConfermaPasswordComponent,
    ConfermaAnnullaModalComponent,
    ModaleGestisciChiudiServizioComponent,
    ModaleCambiaPasswordComponent,
    ModaleModificaVeicoloComponent,
    //responsabili
    TableComponent,
    // direttive
    ChilometraggioPipeDirective,
    FocusColorDirective,
    CurrencyFieldDirective,
    //pipes
    SafePipe,
    ScansionaTargaComponent
  ],
  providers: [
    TitleCasePipe,
  ]
})
export class SharedModule { }
