import { HrefLink } from '../interfaces/generic-links';
import { Hateoas } from './hateoas';

export class Accettazione extends Hateoas<AccettazioneLinks>{

    tipoVendita: string;
    livelloCarburante: number;
    gps: boolean;
    trasmissione: string;
    citta: string;
    numeroRicevuta: number;
    km: number;
    danni: boolean;
    qrCode: string;
    codiceRitiro: string;
    nomeAutonoleggio: string;
    gruppoAppartenenza: string;
    codiceConvenzione: string;
    dipendente: string;
    statoVeicolo: string;
    identificativoAuto: number;
    tipoVeicolo: string;
    daLavare: boolean;
    note: string;
    mappadanni : {};
    importoDanni : number;
    dataAccettazione: Date;

    aggiornaAccettazione : boolean;


    struttura : string;
    veicolo : string;
    cluster : string;
    contrattoId : number;
}

export interface AccettazioneLinks {
    self: HrefLink;
    accettazione: HrefLink;
    cluster: HrefLink;
    dipendente: HrefLink;
    rendiconti: HrefLink;
    statoVeicolo: HrefLink;
    struttura: HrefLink;
    tipoVeicolo: HrefLink;
    tipoVendita: HrefLink;
    trasmissione: HrefLink;
    veicolo: HrefLink;
}

export interface AccettazioneList {
    accettaziones: Accettazione[];
}

