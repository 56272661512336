import {Component, OnInit, ViewChild} from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ShUserNotifyService } from 'src/app/core/services/sh-user-notify.service';
import { HateoasService } from 'src/app/core/services/hateoas.service';
import { environment } from 'src/environments/environment';
import {NonPagatiComponent} from "../../../../operatori/attivita/components/non-pagati/non-pagati.component";

@Component({
  selector: 'app-modale-registrazione-danno',
  templateUrl: './modale-registrazione-danno.component.html',
  styleUrls: ['./modale-registrazione-danno.component.scss'],
})
export class ModaleRegistrazioneDannoComponent implements OnInit {

  @ViewChild('ionContent', {static: false}) private ionContent: any = null;

  danniForm = new FormGroup({});
  //lista per i danni selezionati
  danni = [];

  //nome e coordinate di ogni sezione
  sezioniAuto = {
    "parte superiore" : "72, 9, 130, 0, 243, 12, 246, 85, 132, 95, 72, 88, 55, 49",
    "parte frontale" : "23, 118, 74, 118, 96, 136, 88, 189, 10, 189, 0, 136",
    "parte laterale sinistra" : "116, 151, 200, 119, 282, 121, 306, 141, 308, 190, 113, 190",
    "parte posteriore" : "0, 224, 22, 204, 76, 204, 98, 223, 91, 274, 8, 274",
    "parte laterale destra" : "113, 225, 138, 203, 222, 203, 308, 233, 308, 273, 115, 273"
  };

  //sezioniAuto = {
  //  "tettino" : "145, 26, 214, 26, 214, 68, 145, 68",
  //  "parabrezza" : "24, 125, 72, 125, 77, 136, 19, 136",
  //  "sportello" : "174, 144, 222, 141, 223, 168, 180, 168",
  //  "faro" : "12, 231, 25, 231, 25, 238, 12, 237",
  //  "ruota anteriore" : "265, 243, 279, 257, 265, 272, 250, 257"
  //};



  //coordinate pallino centrale
  //flagSezioni = {
  //  "tettino" : {offsetX : 180, offsetY : 50},
  //  "parabrezza" : {offsetX : 47, offsetY : 130},
  //  "sportello" : {offsetX : 200, offsetY : 160},
  //  "faro" : {offsetX : 19, offsetY : 234},
  //  "ruota anteriore" : {offsetX : 265, offsetY : 258},
  //};

  hadDanni : boolean = false;
  idVeicolo : number;
  cluster : string = '';
  info: boolean = false;

  mappaDanniPrecedenti = {};
  private dannis: any;

  constructor(private modalController: ModalController, private shUserNotifyService : ShUserNotifyService,
              private navParams : NavParams, private hateoasService : HateoasService) {
    this.hadDanni = this.navParams.get('danno');
    this.idVeicolo = this.navParams.get('id');
    this.cluster = this.navParams.get('cluster');
    this.mappaDanniPrecedenti = this.navParams.get('mappaDanniPrecedenti') || {};
    if (this.navParams.get('info')) {
      this.info = this.navParams.get('info');
    }
  }

  ngOnInit() {
    if (this.cluster == 'Autonoleggio') {
      this.danniForm.addControl('importo', new FormControl(''));
      if (this.info) this.danniForm.get("importo").disable();
    }
    //se c'erano dei danni segnalati nella precedente accettazione, li mostro già
    //get danni
    if (this.hadDanni == true && this.idVeicolo) {
      const url = `${environment.endpointUri}/dannis/search/findUltimoDanno?idVeicolo=${this.idVeicolo}`;
      this.hateoasService.get<any>(url).subscribe( result => {
        this.dannis = result;
        this.mappaDanniPrecedenti = Object.assign(this.mappaDanniPrecedenti, result.values);
            this.ricavaIdAndCoordinateFromMappaDanniPrecedenti(this.mappaDanniPrecedenti);
        if (this.cluster == 'Autonoleggio') {
          this.danniForm.get('importo').setValue(result.importo);
        }
      },
      error => {
        console.log('error get danni', error);
      });
    }
    else {
      // else if(this.hadDanni == true && !this.idVeicolo){
      this.ricavaIdAndCoordinateFromMappaDanniPrecedenti(this.mappaDanniPrecedenti);
    }

  }

  private ricavaIdAndCoordinateFromMappaDanniPrecedenti(mappaDanniPrecedenti) {
    for (let [key, value] of Object.entries(mappaDanniPrecedenti)) {
      this.ricavaIdAndCoordinate(key, value);
    }
  }

  clickSezione(id, event) {
    if (this.info) {
      return;
    }
    this.sezioneSelezionata(id, event);
  }

  sezioneSelezionata(id, e) {
    //vedo se l'elemento è stato già inserito
    let esistente : boolean = false;
    this.danni.forEach(element => {
      if (element === id) {
        esistente = true;
        return;
      }
    });
    if(!esistente) {
      //elemento nuovo, viene aggiunto e creato il form control
      this.danni.push(id);
      this.danniForm.addControl(id, new FormControl('', [Validators.required]));
      if (this.info) this.danniForm.get(id).disable();
      //let idCompleto = `point${id}`;
      //creo pallino colorato sulla mappa
      let dimdiv = 20;
      let div = document.createElement('div');
      div.style.backgroundColor = "#009ee0";
      div.style.borderRadius = "50px";
      div.style.opacity =  "0.72";
      div.id = `div${id}`;
      div.style.position = "absolute";
      div.style.height = dimdiv + "px";
      div.style.width = dimdiv + "px";
      div.style.zIndex = '1000';
      //controllo se abbiamo il valore input per danni vecchi
      if (e.valoreInput) {
        //setto valori al form
        this.danniForm.get(id).setValue(e.valoreInput);
        div.style.left = e.layerX +"px";
        div.style.top = e.layerY+"px";
      }
      else {
        div.style.left = (e.layerX-(dimdiv/2)) +"px";
        div.style.top = (e.layerY-(dimdiv/2)) +"px";
      }
      document.getElementById('imgContainer').appendChild(div);




    }
    else {
      this.shUserNotifyService.notifyWarning('Danno già segnalato!');
    }

  }

  ricavaIdAndCoordinate(chiave : string, valore) {
    /**dalla mappa id_(coordinate) : valore_campo mi ricavo i dati e li strutturo per poter creare
     * campi e div sull'immagine
     */
    let id = chiave.replace(/_.*/g, '');
    //ricavo x, y
    let coords = chiave.replace(/(.*_)|[()]/g, '');
    let evento = {
      layerX : coords.replace(/,.*/g, ''),
      layerY : coords.replace(/.*,/g, ''),
      valoreInput : valore
    };
    this.sezioneSelezionata(id, evento);

  }


  registra() {
    //per ogni danno segnalato costruisco una mappa di sezioneauto : descrizione danno
    let mappadanni = {};
    Object.keys(this.danniForm.controls).forEach(key => {
      if (key != 'importo') {
        //salvo coordinate nella chiave della mappa
        let point = document.getElementById(`div${key}`);
        let x = point.offsetLeft;
        let y = point.offsetTop;
        //salvare cordinate nella key
        mappadanni[`${key}_(${x},${y})`] = this.danniForm.get(key).value;
      }
    });
    //se la mappa danni è vuota e non siamo cluster autonoleggio, chiudo modale vuota, altrimenti segnalare danni
    if (Object.keys(mappadanni).length === 0) {
      if (this.cluster != 'Autonoleggio') {
        this.modalController.dismiss({});
      }
      else {
        this.shUserNotifyService.notifyError('Indicare almeno una zona danno');
        return;
      }
    }
    else {
      let obj = {};
      obj['mappadanni'] = mappadanni;
      //se cluster autonoleggio, prendo anche importo
      if (this.cluster == 'Autonoleggio') {
      obj['importo'] = this.danniForm.get('importo').value;
      }
      obj['dannis'] = this.dannis;
      this.modalController.dismiss(obj);
    }

  }

  elimina(id : string) {
    //elimina danno dalla lista, quindi dai controls del form
    let index = this.danni.findIndex( x => x == id);
    this.danni.splice(index, 1);
    this.danniForm.removeControl(id);
    //elimino anche il div colorato
    let element = document.getElementById(`div${id}`);
    element.remove();
  }

  dismiss() {
    this.modalController.dismiss();
  }


  scrollToBottom() {
    this.ionContent.scrollToBottom(300);
  }
}
