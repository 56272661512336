import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appCurrencyField]'
})
export class CurrencyFieldDirective implements OnInit {
  element : HTMLInputElement;

  constructor( private elementRef : ElementRef ) { 
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit() {
    //trasformo tutti i punti in virgole
    //let replace = this.element.value.replace(/,/g, '.'); // tutti punti    
    //rimuovo tutte le occorrenze del . tranne l'ultimo(quello corretto)
    //var normalizedValue = this.removeAllButLast(replace, '.');
    //this.element.value = normalizedValue;
    //se il valore c'è già lo formatto già
    if (this.element.value) {
      let valueConverted = parseFloat(this.element.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
      valueConverted = valueConverted.replace(/.([^.]*)$/, ',' + '$1');
      this.element.value = valueConverted;
    }
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    //trasformo tutti i punti in virgole
    let replace = value.replace(/,/g, '.'); // tutti punti    
    //rimuovo tutte le occorrenze del . tranne l'ultimo(quello corretto)
    var normalizedValue = this.removeAllButLast(replace, '.');
    this.element.value = normalizedValue;
  }

  removeAllButLast(string, token) {
    var parts = string.split(token);
    if (parts[1] === undefined)
      return string;
    else
      return parts.slice(0, -1).join('') + token + parts.slice(-1)
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {

    if (value) {
      let valueConverted = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
      valueConverted = valueConverted.replace(/.([^.]*)$/, ',' + '$1');
      this.element.value = valueConverted;
    }
  }


  @HostListener("keyup", ["$event"])                //tutto l'evento
  onChange(value) {

    if (isNaN(value.target.value)) {
      value.preventDefault();
      // returns true if the variable does NOT contain a valid number
      var newCorrectStr = value.target.value.substring(0, value.target.value.length - 1); //riestto il valore a quello precedente
      this.element.value = newCorrectStr;

    }
    else {
      return; 
    }

  }


}
