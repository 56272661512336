import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-conferma-annulla-modal',
  templateUrl: './conferma-annulla-modal.component.html',
  styleUrls: ['./conferma-annulla-modal.component.scss'],
})
export class ConfermaAnnullaModalComponent implements OnInit {

  @Input() obbligatorio: boolean = false;
  @Input() testo: string = "";

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  procedi() {
    this.modalCtrl.dismiss(true);
  }

  annulla() {
    this.modalCtrl.dismiss();
  }

}
