import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import { filter } from 'rxjs/internal/operators/filter';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnDestroy {

  @HostListener('document:click', ['$event'])
  clickout(event) {
    // hostlistener di click su tutto il document
    // con questo if capisco se sto cliccando al di fuori dell'autocomplete
    if (!this.eRef.nativeElement.contains(event.target) && this.emit) {
        this.simpleForm.get('autoComplete').patchValue('');
        this.arrayFiltered = [];
    }
  }

  simpleForm = new FormGroup({});
  arrayFiltered: string[] = new Array<string>();

  formSubscriptions : Subscription = new Subscription();

  // variabile necessaria per far emittare il valore o settare il valore nel campo autocomplete
  @Input() emit: boolean = true;
  @Input() graficaPDG: boolean = true;
  @Input() startSearch: number = 3;
  @Input() placeHolder: string;
  @Input() propToShow: string;
  @Input() valueToShow: string[];
  @Output() valueToSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() idSpinner: string = '';
  
  constructor(private eRef: ElementRef) { }

  ngOnInit() {
    this.simpleForm.addControl('autoComplete', new FormControl(''));
    this.formSubscriptions = this.simpleForm.get('autoComplete').valueChanges.pipe(distinctUntilChanged()).pipe(
      map((event: any) => {
        if (event.length < this.startSearch) {
          this.arrayFiltered = [];
        }
        return event;
      })
      ,filter(res =>res.length >= this.startSearch)
      ,debounceTime(800)
    ).subscribe((text: string) => {
      this.valueToSearch.emit(text);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.valueToShow && changes.valueToShow.previousValue != changes.valueToShow.currentValue) {
      this.arrayFiltered = changes.valueToShow.currentValue;
    }
  }

  itemoOnClick(item) {
    if (this.emit) {
      this.simpleForm.get('autoComplete').patchValue('');
      this.arrayFiltered = [];
    }else{
      this.simpleForm.get('autoComplete').patchValue(item[this.propToShow]);
    }
    this.valueSelected.emit(item);
  }

  ngOnDestroy() {
    this.formSubscriptions.unsubscribe();
  }
}
