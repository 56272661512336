import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Accettazione } from 'src/app/core/models/accettazione';
import { Veicolo } from 'src/app/core/models/veicolo';

@Component({
  selector: 'app-card-lista-servizi',
  templateUrl: './card-lista-servizi.component.html',
  styleUrls: ['./card-lista-servizi.component.scss'],
})
export class CardListaServiziComponent implements OnInit {

  @Input() servizio: any; //oggetto macroarea
  @Input() nome: string;
  @Input() partenza: string;
  @Input() destinazione: string;
  @Input() cluster : string;
  @Input() accettazione : Accettazione;
  @Input() veicolo : Veicolo;
  @Input() contrattoId : string;
  //id servizio della lista servizi
  @Input() idServizio : string;
  //proprietà attivo e prenotato
  @Input() inErogazione : boolean;
  @Input() prenotato : boolean;
  @Input() gruppoLavoro : boolean;

  @Input() onlyOneService: boolean;

  
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe( params => {
      if (this.onlyOneService && Boolean(params.fromMacro)) this.goDettaglioServizio();
    });
  }

  goDettaglioServizio() {
    let navigationExtras: NavigationExtras = { state: { 
      cluster: this.cluster, 
      servizio : this.servizio, 
      accettazione : this.accettazione, 
      inErogazione : this.inErogazione, 
      prenotato : this.prenotato, 
      idServizio : this.idServizio,
      veicolo : this.veicolo,
      idContratto : this.contrattoId,
      modifica : false,
      parent : null,
      gruppoLavoro : this.gruppoLavoro,
      nomeServizioPadre: this.nome,
    }};
    localStorage.setItem('cluster', this.cluster);
    localStorage.setItem('tipoServizio', JSON.stringify(this.servizio));
    localStorage.setItem('veicolo', JSON.stringify(this.veicolo));
    localStorage.setItem('accettazione', JSON.stringify(this.accettazione));
    localStorage.setItem('inErogazione', String(this.inErogazione));
    localStorage.setItem('prenotato', String(this.prenotato));
    localStorage.setItem('idServizio', String(this.idServizio));
    localStorage.setItem('idContratto', this.contrattoId);
    localStorage.setItem('modifica', String(false));
    localStorage.setItem('parent', null);
    localStorage.setItem('gruppoLavoro', String(this.gruppoLavoro));
    localStorage.setItem('nomeServizioPadre', this.nome);
    this.router.navigate([`operatori/targa/servizi-cliente/servizio/${encodeURIComponent(this.nome.toLocaleLowerCase())}`], navigationExtras);
  }

}
