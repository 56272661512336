import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modale-campo-obbligatorio',
  templateUrl: './modale-campo-obbligatorio.component.html',
  styleUrls: ['./modale-campo-obbligatorio.component.scss'],
})
export class ModaleCampoObbligatorioComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  dismiss() {
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
