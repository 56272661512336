import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'security/login', pathMatch: 'full' },
  {
    path: 'operatori',
    loadChildren: () => import('./operatori/operatori-home.module').then(m => m.OperatoriHomePageModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'responsabili',
    loadChildren: () => import('./responsabili/responsabili-home.module').then( m => m.ResponsabiliHomePageModule)
  },
  {
    path: 'security/login',
    loadChildren: () => import('./core/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'hateoas-example',
    loadChildren: () => import('./core/hateoas-example/hateoas-example.module').then( m => m.HateoasExamplePageModule)
  },
  {
    path: 'security/resetPassword',
    loadChildren: () => import('./core/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'principalImps/resetPassword',
    loadChildren: () => import('./core/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'dettaglio-dashboard',
    loadChildren: () => import('./operatori/home/pages/dettaglio-dashboard/dettaglio-dashboard.module').then( m => m.DettaglioDashboardPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
