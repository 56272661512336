import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { HateoasService } from 'src/app/core/services/hateoas.service';
import { environment } from 'src/environments/environment';
import { GetList } from 'src/app/core/interfaces/generic-links';
import { VeicoloList, Veicolo } from 'src/app/core/models/veicolo';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, observable } from 'rxjs';
import { Passante } from 'src/app/core/models/passante';
import { Rendiconto, RendicontoList } from 'src/app/core/models/rendiconto';
import { Struttura, StrutturaList } from 'src/app/core/models/struttura';
import { MarcaList } from 'src/app/core/models/marca';
import { ModelloList } from 'src/app/core/models/modello';
import { DescDatoList } from 'src/app/core/models/desc-dato';
import { IndirizzoStruttura } from 'src/app/core/models/indirizzo-struttura';
import { Accettazione } from 'src/app/core/models/accettazione';
import { Costanti } from 'src/app/core/models/costanti';
import { SoggettoList, Soggetto } from 'src/app/responsabili/models/soggetto';
import { ComuneFlussoDyLog, ComuneFlussoDyLogList } from 'src/app/core/models/comuneFlussoDyLog';
import { Indirizzo } from 'src/app/responsabili/models/indirizzo';
import { ServizioList } from 'src/app/responsabili/models/servizio';
import { GruppoList, Gruppo } from 'src/app/core/models/gruppo';
import { Gruppi } from 'src/app/responsabili/models/gruppi';
import { PlateRecognitionList, PlateRecognition } from 'src/app/core/models/plateRecognition';
import { ContrattiList, Contratto } from 'src/app/responsabili/models/contratto';
import { ToDoList, ToDoListList } from 'src/app/core/models/to-do-list';
import { Deposito } from '../../models/deposito';
import { Ztl, ZtlList } from 'src/app/responsabili/models/ztl';
@Injectable({
  providedIn: 'root'
})
export class TargaServiceService extends HateoasService{

  constructor(http : HttpClient, private hateoasService : HateoasService) {
    super(http);
  }

  getVeicoloByTarga(targa : string) {
    //FIND VEICOLO BY TARGA
    const url = `${environment.endpointUri}/veicoloes/search/findByTarga?targa=${targa}&projection=Veicolo`;
      return this.hateoasService.get<Veicolo>(url).pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          // console.log('error getVeicoloByTarga', error);
          return Observable.throw(error);
        })
    );
  }

  postAccettazionePassante(passante) {
    //CREAZIONE DI ACCETTAZIONE PASSANTE
    const urlPassante: string = `${environment.endpointUri}/accettaziones/search/saveAccettazionePassante`
    return this.hateoasService.createObject<Passante>(urlPassante, passante);
  }

  postAccettazioneGenerica(accettazioneObj) {
    //CREAZIONE ACCETTAZIONE
    const urlAccettazione : string = `${environment.endpointUri}/accettaziones`;
    return this.hateoasService.createObject<Accettazione>(urlAccettazione, accettazioneObj);
  }

  updateAccettazione(id : number, accettazioneObj) {
    //put accettazione
    const url = `${environment.endpointUri}/accettaziones/${id}`;
    return this.hateoasService.patchObject<Accettazione>(url, accettazioneObj);
  }

  getAccettazioneById(idAccettazione : number) {
    const urlAccettazione : string = `${environment.endpointUri}/accettaziones/${idAccettazione}`;
    return this.hateoasService.get<Accettazione>(urlAccettazione);
  }

  getVeicoloByProjectionAndId(id: number) {
    const urlVeicolo = `${environment.endpointUri}/veicoloes/${id}?projection=Veicolo`;
    return this.hateoasService.get<any>(urlVeicolo);
  }

  createVeicolo(veicolo) {
    const urlVeicolo: string = `${environment.endpointUri}/veicoloes`
    return this.hateoasService.createObject<Veicolo>(urlVeicolo, veicolo);
  }

  patchVeicolo(veicolo, id) {
    const urlVeicolo: string = `${environment.endpointUri}/veicoloes/${id}`
    return this.hateoasService.patchObject<Veicolo>(urlVeicolo, veicolo);
  }

  getServiziByTypeAndStructAndCluster(cluster: string, tipoServizio: string, dimVeicolo: string, strutturaId : number, contrattoId) {
    //FIND SERVIZI BY TYPE STRUTTURA AND CLUSTER
    const url = `${environment.endpointUri}/servizios/search/findByStrutturaAndClusterAndContratto?tipologia=${tipoServizio}&strutturaId=${strutturaId}&cluster=${cluster}&dimensione=${dimVeicolo}&contrattoId=${contrattoId}`;
    return this.hateoasService.get<GetList<ServizioList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
  );
  }

  getIndirizzoStruttura(url) {
    //get di indirizzo struttura dal link indirizzo di struttura
    return this.hateoasService.get<IndirizzoStruttura>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
  );

  }

  getStrutturaById(id) {
    const url = `${environment.endpointUri}/strutturas/${id}`;
    return this.hateoasService.get<Struttura>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
  );
  }

  getStrutture(url) {
    return this.hateoasService.get<GetList<StrutturaList>>(url);
  }

  checkAbbonamentoByTargaAndStruttura(targa : string, struttura : number) {
    //verifica l'esistenza di un abbonamento per la specifica targa e struttura
    const urlAbbonato = `${environment.endpointUri}/accettaziones/search/verificaContrattoAbbonamentoAttivo?targa=${targa}&strutturaId=${struttura}`;
    return this.hateoasService.get<boolean>(urlAbbonato);
  }

  getAutocompleteList(text, cluster, strutturaId : number, autocompleteObj) {
    //autocomplete clienti (abbonato o autonoleggio)
    let url = `${environment.endpointUri}/accettaziones/search/filtraSoggettoByNome?&denominazione=${text}&idStruttura=${strutturaId}&cluster=${cluster}`;
    return this.hateoasService.get<string>(url, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }
  /* getAutocompleteList(text, cluster, strutturaId : number, autocompleteObj) {
    //autocomplete clienti (abbonato o autonoleggio)
    let url = `${environment.endpointUri}/soggettoes/search/searchByCustomerNameAndCluster?&denominazione=${text}&idStruttura=${strutturaId}&cluster=${cluster}`;
    return this.hateoasService.get<GetList<SoggettoList>>(url, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }*/

  getValoreByServizio(tipoServizio: string) {
    const url = `${environment.endpointUri}/descDatoes/search/findByValoreServizio?valore=${tipoServizio}&projection=DescDati`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getMarcaAuto(text, autocompleteObj: any = null) {
    const urlMarca = `${environment.endpointUri}/marcas/search/findByMarca?marca=${text}`;
    return this.hateoasService.get<GetList<MarcaList>>(urlMarca, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getModelloAuto(marcaId, text, autocompleteObj: any = null) {
    const urlModello = `${environment.endpointUri}/modelloes/search/findByModello?modello=${text}&marca=${marcaId}`;
    return this.hateoasService.get<GetList<ModelloList>>(urlModello, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getDescDatiByServizio(servizio) {
    const url = `${environment.endpointUri}/descDatoes/search/findByValoreServizio?valore=${servizio}&projection=DescDati`;
    return this.hateoasService.get<GetList<DescDatoList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  postAperturaServizio(servizioId, strutturaId, accettazioneId, rendicontoObj : Rendiconto, depositoId: number) {
    //SALVATAGGIO APERTURA SERVIZIO
    let url = `${environment.endpointUri}/rendicontoes/search/apri?idServizio=${servizioId}&idStruttura=${strutturaId}&idAccettazione=${accettazioneId}`;
    if (depositoId) url += `&idStrutturaDeposito=${depositoId}`;
    return this.hateoasService.createObject<Rendiconto>(url, rendicontoObj);
  }

  updateChiusuraServizio(rendicontoId, cluster, rendicontoObj : Rendiconto) {
    //SALVATAGGIO CHIUSURA SERVIZIO
    const url = `${environment.endpointUri}/rendicontoes/search/${rendicontoId}/chiudi?cluster=${cluster}`;
    return this.hateoasService.updateObject<Rendiconto>(url, rendicontoObj);
  }

  postPrenotazioneServizio(servizioId, strutturaId, accettazioneId, rendicontoObj : Rendiconto) {
    //SALVATAGGIO PRENOTAZIONE SERVIZIO
    const url = `${environment.endpointUri}/rendicontoes/search/prenota?idServizio=${servizioId}&idStruttura=${strutturaId}&idAccettazione=${accettazioneId}`;
    return this.hateoasService.createObject<Rendiconto>(url, rendicontoObj);
  }

  postAperturaServizioPrenotato(servizioId, strutturaId, accettazioneId,rendicontoId, rendicontoObj : Rendiconto) {
    // salvataggio di apertura di un servizio prenotato
    const urlPrenotato = `${environment.endpointUri}/rendicontoes/search/apri?idServizio=${servizioId}&idStruttura=${strutturaId}&idAccettazione=${accettazioneId}&idRendiconto=${rendicontoId}`;
    return this.hateoasService.createObject<Rendiconto>(urlPrenotato, rendicontoObj)
  }

  postAnnullamentoServizio(idRendiconto, rendicontoObj : Rendiconto) {
    // annullamento servizio (rendiconto)
    const url = `${environment.endpointUri}/rendicontoes/search/elimina?idRendiconto=${idRendiconto}`;
    return this.hateoasService.createObject<Rendiconto>(url, rendicontoObj);
  }

  getServiziAttivi(idVeicolo, idStruttura, idContratto, nomeMacroarea) {
    //GET DEI SERVIZI ATTIVI (LISTA SERVIZI PAGE, NON MACROAREE)
    const url = `${environment.endpointUri}/servizios/search/findServiziAttiviVettura?veicoloId=${idVeicolo}&strutturaId=${idStruttura}&contrattoId=${idContratto}&tipoServizio=${nomeMacroarea}`;
    return this.hateoasService.get<GetList<any>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );

  }

  getServiziPrenotati(idVeicolo, idStruttura, idContratto, nomeMacroarea) {
    //GET DEI SERVIZI PRENOTATI (LISTA SERVIZI PAGE, NON MACROAREE)
    const url = `${environment.endpointUri}/servizios/search/findServiziPrenotatiVettura?veicoloId=${idVeicolo}&strutturaId=${idStruttura}&contrattoId=${idContratto}&tipoServizio=${nomeMacroarea}`;
    return this.hateoasService.get<GetList<any>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );

  }

  getRendiconto(idServizio, idAccettazione, idStruttura, idContratto, statoServizio) {
    const urlRendiconto = `${environment.endpointUri}/rendicontoes/search/findRendicontoAttivoPrenotato?servizioId=${idServizio}&accettazioneId=${idAccettazione}&strutturaId=${idStruttura}&contrattoId=${idContratto}&stato=${statoServizio}&projection=StoricoRendiconto`;
    return this.hateoasService.get<Rendiconto>(urlRendiconto).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getContrattiAbbonato(strutturaId, cluster, targa, dimensione) {
    //get per cluster abbonato dei contratti attivi (macroaree)
    const urlAbbonato = `${environment.endpointUri}/contrattoes/search/findContrattiAttivi?idStruttura=${strutturaId}&cluster=${cluster}&targa=${targa}`;
    return this.hateoasService.get<GetList<any>>(urlAbbonato).pipe(
      //switchMap(response => {
        //return this.getContrattiDimensione(dimensione, response, strutturaId, cluster);
      //}),
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  //getContrattiDimensione(dimensione, listaUno, strutturaId, cluster) {
  //  const url = `${environment.endpointUri}/contrattoes/search/findContrattiAttiviDimensione?dimensione=${dimensione}&idStruttura=${strutturaId}&cluster=${cluster}`;
  //  return this.hateoasService.get<GetList<any>>(url).pipe(
  //    map(response => {
  //      return [...listaUno._embedded.contrattoes, ...response._embedded.contrattoes]
  //    })
  //  );
  //}

  getContrattiAutonoleggio(strutturaId, cluster, nome) {
    //get dei contratti attivi per cluster autonoleggio o struttura convenzionata
    const url = `${environment.endpointUri}/contrattoes/search/findContrattiAttiviSoggetto?strutturaId=${strutturaId}&cluster=${cluster}&nome=${nome}&projection=DettaglioContratto`;
    return this.hateoasService.get<GetList<any>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  findLastAccettazione(id : number,cluster: string) {
    // const url = `${environment.endpointUri}/accettaziones/search/findFirstAccettazioneByVeicolo?id=${id}`;
    const url = `${environment.endpointUri}/accettaziones/search/findLastAccettazioneByVeicoloAndCluster?id=${id}&cluster=${cluster}&projection=AccettazioneProjection`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }


   getPropertyByUrl(url : string) {
    return this.hateoasService.get<any>(url);
  }

  getStoricoServizi(targa : string, idStruttura, page, size) {
    const url = `${environment.endpointUri}/rendicontoes/search/findStoricoRendiconto?targa=${targa}&dataFine=&strutturaId=${idStruttura}&page=${page}&size=${size}&projection=StoricoRendiconto`;
    return this.hateoasService.get<GetList<RendicontoList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  patchRendiconto(id : number, rendicontoObj) {
    const url = `${environment.endpointUri}/rendicontoes/${id}`;
    return this.hateoasService.patchObject(url, rendicontoObj);
  }

  getScontrino(cluster : string, targa : string, idStruttura: number, idAccettazione?) {
    //dati di fatturazione per lo scontrino
    //const url = `${environment.endpointUri}/rendicontoes/search/generaScontrino?targa=${targa}`;
    //const url = `${environment.endpointUri}/rendicontoes/search/generaScontrino?idAccettazione=${idAccettazione}`;
    const url = `${environment.endpointUri}/rendicontoes/search/generaScontrino?idAccettazione=${idAccettazione}&cluster=${cluster}&targa=${targa}&idStruttura=${idStruttura}`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  postSalvaPagamento(pagamentoObj) {
    //salvataggio pagamento fattura
    const url = `${environment.endpointUri}/rendicontoes/search/salvaPagamenti`;
    return this.hateoasService.createObject<any>(url, pagamentoObj);
  }

  getRendicontoById(idRendiconto) {
    const url = `${environment.endpointUri}/rendicontoes/${idRendiconto}?projection=StoricoRendiconto`;
    return this.hateoasService.get<Rendiconto>(url);
  }

  getRendicontoByQr(strutturaId, codice) {
    const url =`${environment.endpointUri}/rendicontoes/search/findRendicontoByCodiceRitiro?codice=${codice}&strutturaId=${strutturaId}`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getSoggettoByIva(iva) {
    const url = `${environment.endpointUri}/soggettoes/search/findByPartitaIva?piva=${iva}`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getSoggettoByCodiceFiscale(cf) {
    const url = `${environment.endpointUri}/soggettoes/search/findClienteByCodiceFiscale?codiceFiscale=${cf}`;
    return this.hateoasService.get<any>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getCittaAutocomplete(citta, autocompleteObj) {
    const url = `${environment.endpointUri}/comuneFlussoDyLogs/search/findByComuneAutocomplete?descrizione=${citta}`;
    return this.hateoasService.get<GetList<ComuneFlussoDyLogList>>(url, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  createIndirizzo(indirizzoObj) {
    const url = `${environment.endpointUri}/indirizzoes`;
    return this.hateoasService.createObject<Indirizzo>(url, indirizzoObj);
  }

  createCliente(tipoPersona, personaObj) {
    const url = `${environment.endpointUri}/${tipoPersona}`;
    return this.hateoasService.createObject<Soggetto>(url, personaObj);
  }

  updateCliente(linkSelf, clienteObj) {
    return this.hateoasService.updateObject<Soggetto>(linkSelf, clienteObj);
  }

  getServizioSottoservizi(idServizio) {
    //?&projection=Servizio
    const url = `${environment.endpointUri}/servizios/${idServizio}/sottoServizi?&projection=Servizio`;
    return this.hateoasService.get<GetList<ServizioList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getSottoserviziAttivi(veicoloId, strutturaId, contrattoId, parentId) {
    const url = `${environment.endpointUri}/servizios/search/findSottoServiziAttiviVettura?veicoloId=${veicoloId}&strutturaId=${strutturaId}&contrattoId=${contrattoId}&parentId=${parentId}`;
    return this.hateoasService.get<GetList<ServizioList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getSottoserviziPrenotati(veicoloId, strutturaId, contrattoId, parentId) {
    const url = `${environment.endpointUri}/servizios/search/findSottoServiziPrenotatiVettura?veicoloId=${veicoloId}&strutturaId=${strutturaId}&contrattoId=${contrattoId}&parentId=${parentId}`;
    return this.hateoasService.get<GetList<ServizioList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  getGruppiByStruttura(strutturaId) {
    const url = `${environment.endpointUri}/gruppoes/search/findByStruttura?struttura=${strutturaId}`;
    return this.hateoasService.get<GetList<GruppoList>>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  findDipendenti(text, autocompleteObj) {
    const url = `${environment.endpointUri}/dipendentes/search/findDipendenti?search=${text}`
    return this.hateoasService.get<GetList<SoggettoList>>(url, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  findDipendentiAttivi(text, autocompleteObj) {
    const url = `${environment.endpointUri}/dipendentes/search/findDipendentiAttivi?search=${text}`
    return this.hateoasService.get<GetList<SoggettoList>>(url, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  postGruppo(obj : Gruppo) {
    const url = `${environment.endpointUri}/gruppoes`;
    return this.hateoasService.createObject<Gruppo>(url, obj);
  }

  patchGruppo(gruppoId : number, obj : Gruppo) {
    const url = `${environment.endpointUri}/gruppoes/${gruppoId}`;
    return this.hateoasService.patchObject<Gruppo>(url, obj);
  }

  deleteGruppo(gruppoId : number) {
    const url = `${environment.endpointUri}/gruppoes/${gruppoId}`;
    return this.hateoasService.deleteObject(url);
  }

  getGruppiByNomeAndStruttura(strutturaId, text, autocompleteObj) {
    const url = `${environment.endpointUri}/gruppoes/search/findByNomeEStruttura?struttura=${strutturaId}&nome=${text}`;
    return this.hateoasService.get<GetList<GruppoList>>(url, autocompleteObj).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  countNonPagatiByAccettazione(accettazioneId : number) {
    const url = `${environment.endpointUri}/rendicontoes/search/countNonPagatiByAccettazione?accettazioneId=${accettazioneId}`;
    return this.hateoasService.get<number>(url).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  readPlateForRecognition(file) {
    return this.http.post(`${environment.endpointUri}/plateRecognition/search/read`, file, {responseType: 'text'});
  }

  getPlateRecognitions(idStruttura : number) {
    const url = `${environment.endpointUri}/plateRecognitions/search/findIngressi?strutturaId=${idStruttura}`;
    return this.hateoasService.get<GetList<PlateRecognitionList>>(url);
  }

  putPresaInCaricoPlate(idPlate : number, plateObj) {
    const url = `${environment.endpointUri}/plateRecognition/search/prendiInCarico/${idPlate}`;
    return this.hateoasService.updateObject<PlateRecognition>(url, plateObj);
  }

  findContrattiByCliente(idStruttura : number, cluster : string, nome : string, autocompleteObj) {
    const url = `${environment.endpointUri}/contrattoes/search/findSoggettoContratto?nome=${nome}&strutturaId=${idStruttura}&cluster=${cluster}`;
    return this.hateoasService.get<GetList<ContrattiList>>(url, autocompleteObj);
  }

  getContrattoById(id : number) {
    const url = `${environment.endpointUri}/contrattoes/${id}`;
    return this.hateoasService.get<Contratto>(url);
  }

  verificaTargheContemporanee(contrattoId : number) {
    const url = `${environment.endpointUri}/accettaziones/search/verificaTargheContemporanee?contrattoId=${contrattoId}`;
    return this.hateoasService.get<boolean>(url);
  }

  consentiAccesso(idContratto, idStruttura: number, dimensione: string, targa: string) {
    const url = `${environment.endpointUri}/accettaziones/search/consentiAccesso?idContratto=${idContratto}&idStruttura=${idStruttura}&dimensione=${dimensione}&targa=${targa}`;
    return this.hateoasService.get<any>(url);
  }

  findContrattiByCodice(idGarage: number, text: string, autocompleteObj = null) {
    const url = `${environment.endpointUri}/contrattoes/search/findContrattiByCodice?idStruttura=${idGarage}&codice=${text}&projection=ContrattoLight`;
    return this.hateoasService.get<GetList<ContrattiList>>(url, autocompleteObj);
  }

  modificaImportoRendiconto(object) {
    const url = `${environment.endpointUri}/rendicontoes/search/modificaImportoRendiconto`;
    return this.hateoasService.createObject<any>(url, object);
  }

  verificaPassword(pw: string) {
    const url = `${environment.endpointUri}/dipendentes/search/verificaPassword?password=${pw}`;
    return this.hateoasService.get<any>(url);
  }

  postToDo(obj) {
    const url = `${environment.endpointUri}/toDoLists`;
    return this.hateoasService.createObject<ToDoList>(url, obj);
  }

  findToDo(idStruttura: number) {
    //const url = `${environment.endpointUri}/toDoLists/search/findToDo?idStruttura=${idStruttura}&projection=ToDo`;
    const url = `${environment.endpointUri}/rendicontoes/search/findToDoList?idStruttura=${idStruttura}`;
    return this.hateoasService.get<ToDoList[]>(url);
  }

  deleteToDo(id: number) {
    const url: string = `${environment.endpointUri}/toDoLists/${id}`;
    return this.hateoasService.deleteObject(url);
  }

  getCountToDo(idStruttura: number) {
    const url = `${environment.endpointUri}/rendicontoes/search/getCountByIdStruttura?idStruttura=${idStruttura}`;
    return this.hateoasService.get<number>(url);
  }

  findUltimoDanno(idVeicolo: number) {
    const url = `${environment.endpointUri}/dannis/search/findUltimoDanno?idVeicolo=${idVeicolo}`;
    return this.hateoasService.get<any>(url);

  }

  putDannis(idDannis: number, bodyDannis: object) {
    const url = `${environment.endpointUri}/dannis/${idDannis}`;
    return this.hateoasService.updateObject<any>(url, bodyDannis);
  }

  postDannis(bodyDannis: object) {
    const url = `${environment.endpointUri}/dannis`;
    return this.hateoasService.createObject<any>(url, bodyDannis);
  }

  recuperaAbbonato(targa: string, idStruttura: number) {
    const url = `${environment.endpointUri}/accettaziones/search/recuperaAbbonato?targa=${targa}&strutturaId=${idStruttura}`;
    return this.hateoasService.get<Soggetto>(url);
  }

  findByStrutturaAndContrattoAndTarga(idStruttura: number, idContratto: any, targa: string) {
    const url = `${environment.endpointUri}/servizios/search/findByStrutturaAndContrattoAndTarga?strutturaId=${idStruttura}&contrattoId=${idContratto}&targa=${targa}`;
    return this.hateoasService.get<GetList<ServizioList>>(url);
  }

  searchClienti(text: string, autocompleteObj?) {
    const url = `${environment.endpointUri}/soggettoes/search/getClientiFattura?search=${text}`;
    return this.hateoasService.get<GetList<SoggettoList>>(url, autocompleteObj);
  }

  fastcheckByTarga(targa: string, idStruttura: number) {
    const url = `${environment.endpointUri}/fastcheck/fastcheckByTarga?targa=${targa}&struttura=${idStruttura}`;
    return this.hateoasService.get<any>(url);
  }

  fastcheckByQRcode(code: string, idStruttura: number) {
    const url = `${environment.endpointUri}/fastcheck/fastcheckByQRcode?codice=${code}&struttura=${idStruttura}`;
    return this.hateoasService.get<any>(url);
  }

  fastCheckIn(fastcheck) {
    const url = `${environment.endpointUri}/fastcheck/apriFastcheck`;
    return this.hateoasService.createObject<any>(url, fastcheck);
  }

  changeDeposito(obj: {idRendiconto: number, idDipendente: number, idStruttura: number, flagTrasferimento : boolean }) {
    const url = `${environment.endpointUri}/rendicontoes/search/modificaParcheggiataPresso`;
    return this.hateoasService.createObject<any>(url, obj);
  }

  getDashboardData(idStruttura: number) {
    const url = `${environment.endpointUri}/dashboard/stats/getDashboard?idStruttura=${idStruttura}`;
    return this.hateoasService.get<any>(url);
  }

  getDashboardDetails(idStruttura: number, name: string) {
    const url = `${environment.endpointUri}/rendicontoes/search/getDettaglioDashboard?idStruttura=${idStruttura}&dettaglio=${name}`;
    return this.hateoasService.get<Rendiconto[]>(url);
  }

  getDashboardZtl(idStruttura: number){
    const url = `${environment.endpointUri}/zTLs/search/getTargheDaRecuperare?idStruttura=${idStruttura}&projection=ZTL`;
    return this.hateoasService.get<GetList<ZtlList>>(url);
  }
}
