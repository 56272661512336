import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/shared/custom-snackbar/custom-snackbar.component';
import { throwError, Observable, from, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {OCR, OCRResult, OCRSourceType} from '@ionic-native/ocr/ngx';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  
  public readonly monthNames : string = "Gennaio, Febbraio, Marzo, Aprile, Maggio, Giugno, Luglio, Agosto, Settembre, Ottobre, Novembre, Dicembre";
  public readonly campiObbligatori : string = "*I campi contrassegnati da asterisco sono obbligatori.";
  static snackBar: MatSnackBar;

  public currentUrl: string;
  public previousUrl: string;

  private loggedSource = new Subject<any>();
  loggedConfirmed = this.loggedSource.asObservable();

  private toOperatoriSource = new Subject<any>();
  toOperatoriConfirmed = this.toOperatoriSource.asObservable();

  constructor(private ocr: OCR) { }

  getIdBySelfHref(selfHref) {
    let index = selfHref.lastIndexOf("/");
    let id = '1';
    if (index != -1) id = selfHref.substring(index + 1, selfHref.length);
    return Number(id);
  }


  static showMessage(message, type?) {
    type = type!=null ? type: 'Info';
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 5000,
        panelClass: type,
        data: { message: message, snackType: type }
    });
}

  // Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  

  changeStruttura() {
    this.loggedSource.next(true);
  }

  toOperatoriChange() {
    this.toOperatoriSource.next(true);
  }

  readTextOCR(base64Text): any {
    return this.ocr.recText(OCRSourceType.BASE64, base64Text)
        .then((res: OCRResult) => {
          let targa = '';
          if (res.blocks.blocktext[0]) {
            targa = res.blocks.blocktext[0].replace(/[^0-9a-zA-Z]+/g, '');
          }
          return targa;
        })
        .catch((error: any) => console.error('ocr error', error));
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
