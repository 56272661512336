import { LOCALE_ID, NgModule, ViewContainerRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DstSecurityModule } from 'dst-security';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConstantsEndpoint } from './constantsEndpoint';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CustomInterceptorService } from './core/services/custom-interceptor.service';
import { InterceptorService, HandleErrorServiceService } from 'dst-security';
// import { HandleErrorService } from './core/services/handle-error.service';
import { MatButtonModule, MatCheckboxModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule } from '@angular/material';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatePipe, registerLocaleData } from '@angular/common';
import { CustomSnackbarComponent } from './shared/custom-snackbar/custom-snackbar.component';
import { SharedModule } from './shared/shared.module';
import { Printer } from '@ionic-native/printer/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import localeIt from '@angular/common/locales/it';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { OCR } from '@ionic-native/ocr/ngx';
import {Camera} from "@ionic-native/camera/ngx";

registerLocaleData(localeIt);
@NgModule({
  declarations: [AppComponent, CustomSnackbarComponent],
  entryComponents: [CustomSnackbarComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    DstSecurityModule,
    HttpClientModule,
    SharedModule,
        BrowserAnimationsModule, MatButtonModule, MatCheckboxModule, 
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), 
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  providers: [
    StatusBar,
    DatePipe,
    SplashScreen,
    Printer,
    AppVersion,
    MobileAccessibility,
    CameraPreview,
    Camera,
    OCR,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'environment', useValue: ConstantsEndpoint },
    {
      provide: HTTP_INTERCEPTORS,  
      useClass: InterceptorService,  
      multi: true  
    },
    // { provide: HandleErrorServiceService, useClass: HandleErrorService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'it-IT'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
