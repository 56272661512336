import { Injectable } from '@angular/core';
import { CanLoad, Router, Route } from '@angular/router';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor(private router: Router, private utilityService: UtilityService) {}

  canLoad(route: Route): boolean {
    let token: string = localStorage.getItem("x-auth-token");
    if (token != null && token != undefined && token.length > 0) {
      // qui controlleremo i ruoli o la discriminante per indirizzare l'utente su responsabili o operatori
      return true;
    } else {
      this.router.navigate(['security/login'])
      return false;
    }
  }
}
