import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { HateoasService } from 'src/app/core/services/hateoas.service';
import { Inventario, InventarioList } from 'src/app/core/models/inventario';
import { GetList } from 'src/app/core/interfaces/generic-links';
import { Veicolo, VeicoloList } from 'src/app/core/models/veicolo';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Accettazione, AccettazioneList } from 'src/app/core/models/accettazione';
import { Struttura } from 'src/app/core/models/struttura';

@Component({
  selector: 'app-modale-riepilogo-inventario',
  templateUrl: './modale-riepilogo-inventario.component.html',
  styleUrls: ['./modale-riepilogo-inventario.component.scss'],
})
export class ModaleRiepilogoInventarioComponent implements OnInit {

  listaMacchine: string[] = [];
  struttura : Struttura;

  titleTarghe: string = 'inInventario';
  @Input() inventario: Inventario;

  constructor(navParams: NavParams, private modalCtrl: ModalController,
    private utilityService: UtilityService,
    private hateoasService: HateoasService) {

  }

  ngOnInit() {

    //recupero da local storage la struttura
    if (localStorage.getItem('struttura') != null) {
      this.struttura = JSON.parse(localStorage.getItem('struttura'));
    }

    // this.listaTarghe = this.data
    this.findAllRiepInventario();
  }

  dismiss() {

    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


  findAllRiepInventario() {

    this.titleTarghe = 'inInventario';
    this.coloraBottoni(['nonInventario', 'nonInGarage']);
    let array = this.inventario.targhe.split(';');
    //l'ultimo è una stringa vuota
    array.splice(array.length -1);
    this.listaMacchine = array;
    //let url = this.inventario._links.veicoli.href.replace('{?projection}', "");
    //url = `${url}?projection=VeicoliInventario`;
    //this.hateoasService.get<string[]>(url).subscribe(res => {
//
    //  this.listaMacchine = res;
    //},
    //error => {
    //  console.log('error find all', error);
    //});
  }

  nonInventario() {
    this.titleTarghe = 'nonInventario';
    this.coloraBottoni(['inInventario', 'nonInGarage']);

    let idInventario = this.utilityService.getIdBySelfHref(this.inventario._links.self.href);
    let idStruttura = this.utilityService.getIdBySelfHref(this.struttura._links.self.href);
    let url = `${environment.endpointUri}/inventario/search/autoNonInInventario?inventario=${idInventario}&struttura=${idStruttura}`;
    this.hateoasService.get<string[]>(url).subscribe(res => {
      this.listaMacchine = res;
    },
    error => {
      if (error.status == 404) {
        this.listaMacchine = [];
      }
    });
  }

  nonInGarage() {
    this.titleTarghe = 'nonInGarage';
    this.coloraBottoni(['nonInventario', 'inInventario']);
  
    let idInventario = this.utilityService.getIdBySelfHref(this.inventario._links.self.href);
    let idStruttura = this.utilityService.getIdBySelfHref(this.struttura._links.self.href);
    let url = `${environment.endpointUri}/inventario/search/autoNonInGarage?inventario=${idInventario}&struttura=${idStruttura}`;
    this.hateoasService.get<string[]>(url).subscribe(res => {
      this.listaMacchine = res;
    },
    error => {
      if (error.status == 404) {
        this.listaMacchine = [];
      }
    });
  }

  coloraBottoni(listaBottoni) {
    document.getElementById(this.titleTarghe).style.borderColor = 'var(--ion-color-muoviamo-secondary)';
    document.getElementById(this.titleTarghe).style.backgroundColor = 'var(--ion-color-muoviamo-secondary)';
    document.getElementById(this.titleTarghe).style.color = 'white';
    listaBottoni.forEach(element => {
      document.getElementById(element).style.borderColor = '#c4c4c4';
      document.getElementById(element).style.backgroundColor = 'white';
      document.getElementById(element).style.color = '#c4c4c4';
    });
  }


}