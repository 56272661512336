import { Struttura } from './struttura';
import { Hateoas } from './hateoas';
import { HrefLink } from '../interfaces/generic-links';
import { Accettazione } from './accettazione';

export class Inventario extends Hateoas<InventarioLinks> {

    dataApertura: Date;
    dataChiusura: Date;
    dipendente: string; //link
    veicoli: string[];
    note: string;
    stato: string;
    struttura: string; //link
    //new code - lista di targhe concatenate da ;
    targhe : string;
}

export class StatoInventarioConst {
    readonly aperto: string = 'aperto';
    readonly chiuso: string = 'chiuso';

}

export interface InventarioLinks {
    self: HrefLink;
    inventario: HrefLink;
    struttura: HrefLink;
    veicoli: HrefLink;
    dipendente: HrefLink;
}

export interface InventarioList {
    inventarios: Inventario[];
    veicoloes: Accettazione[];
}