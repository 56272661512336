import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-notifications',
  templateUrl: './card-notifications.component.html',
  styleUrls: ['./card-notifications.component.scss'],
})
export class CardNotificationsComponent implements OnInit {
  @Input() notifica;
  icon: string;

  isNotRead: boolean = false;

  constructor() { }

  ngOnInit() {
    if(this.notifica.tipoServizio == 'Lavaggio') {
      this.icon = 'J';
    }
    else if(this.notifica.tipoServizio == 'Flotta') {
      this.icon = 'H';
    }
  }
  
  leggi() {
    this.isNotRead = true;
  }
  
}
