import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { Contratto } from 'src/app/responsabili/models/contratto';
import { environment } from 'src/environments/environment';
import { TargaServiceService } from 'src/app/operatori/home/services/targa-service.service';

@Component({
  selector: 'app-modale-targa-sostitutiva',
  templateUrl: './modale-targa-sostitutiva.component.html',
  styleUrls: ['./modale-targa-sostitutiva.component.scss'],
})
export class ModaleTargaSostitutivaComponent implements OnInit, OnDestroy {
  
  @Input() targa : string;
  @Input() idStruttura : number;
  formTarga = new FormGroup({});
  targaSubscription : Subscription = new Subscription();
  contrattoSub : Subscription = new Subscription();
  arrayContratti : Contratto[] = [];
  contrattoSelezionato : Contratto;
  constructor(private modalCtrl : ModalController, private targaService : TargaServiceService) { }

  ngOnInit() {
    this.formTarga.addControl('targaSostitutiva', new FormControl('', Validators.required));
    //value changes radio per targa sostitutiva, per aggiunta di campo contratto
    let targaSub = this.formTarga.get('targaSostitutiva').valueChanges.pipe(
      distinctUntilChanged()
      ,debounceTime(600)
    ).subscribe( value => {
      if (value != undefined && value != null) {
        //se targa sostitutiva true, aggiungo campo se non è già presente
        if (value == true && !this.formTarga.controls.contratto) {
          this.formTarga.addControl('contratto', new FormControl('', Validators.required));
          //value changes campo contratto per autocomplete
          let sub = this.formTarga.get('contratto').valueChanges.pipe(
            distinctUntilChanged()
            ,debounceTime(800)
            ,filter(text => text.length == 0 || text.length > 2)
          ).subscribe(result => {
            this.findContratti(result);
          });
          this.contrattoSub.add(sub);
        }
        //se false, elimino campo nel caso in cui ci sia
        else if (value == false && this.formTarga.controls.contratto) {
          this.formTarga.removeControl('contratto');
          this.contrattoSub.unsubscribe();
        }
      }
    });
    this.targaSubscription.add(targaSub);
  }

  conferma() {
    this.modalCtrl.dismiss({
      targaSostitutiva : this.formTarga.get('targaSostitutiva').value,
      contratto : this.contrattoSelezionato,
    });
  }

  annulla() {
    this.modalCtrl.dismiss();
  }

  findContratti(valore : string) {
    //autocomplete contratto
    this.targaService.findContrattiByCliente(this.idStruttura, "Abbonato", valore, {meta: {isAutocomplete: true, nomeAutocomplete: "contrattoSpinner"}}).subscribe( result => {
      this.arrayContratti = result._embedded.contrattoes;
    },
    error => {
      console.log('error find contratti', error);
    });
  }

  chooseContratto(contratto) {
    this.contrattoSelezionato = contratto;
    this.arrayContratti = [];
    this.formTarga.get('contratto').setValue(contratto.nome, {emitEvent: false});
  }

  ngOnDestroy() {
    this.targaSubscription.unsubscribe();
  }
}
