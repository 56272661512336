import { SelfLink } from '../interfaces/generic-links';

export class Hateoas<T> {
    _links: SelfLink & T;
    id?: number;

    getIdBySelf(): number {
        return Number(this._links.self.href.split("/").reverse()[0]);
    }

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}