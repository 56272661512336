import { Component, OnInit, ViewChild, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, Sort, MatTooltip } from '@angular/material';
import { UtilityService } from '../../../../core/services/utility.service';
import { Page } from 'src/app/core/interfaces/page';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  
  @Input() items: any[] = [];
  @Input() arrayTh: string[];
  @Input() displayedColumns: string[];
  @Input() arrayBtn: string[];
  @Input() arrayIcn: string[];
  @Input() arrayBtnName: string[];
  @Input() arraySort: string[];
  @Input() page: Page;
  @Input() sort: any;
  @Output() azioniFunction: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() pageSelected: EventEmitter<Page | any> = new EventEmitter<Page | any>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('tooltip', { static: false}) tooltip: MatTooltip;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource(this.items);
  resultsPerPage: number[] = [5, 10, 20];

  constructor(private utilityService: UtilityService) { }

  ngOnInit() { 
    if (this.arraySort == undefined) this.arraySort = [];
  }

  clickFunction(type, element) {
    // recupero l'id cliccato dalla proprietà self
    // let id: number = this.utilityService.getIdBySelfHref(element._links.self.href);

    this.tooltip.hide();

    setTimeout(() => {
      this.azioniFunction.emit({type: type, element: element});
    }, 100);
  }

  /* dichiaro una costante item che registrerà i cambiamenti degli items. Ad ogni cambiamento aggiorno i dati della tabella accedendo alla proprietà currentValue di item. */
  ngOnChanges(changes: SimpleChanges) {
    const changedItems = changes.items;
    if (changedItems) {
      // recupero items 
      this.dataSource = new MatTableDataSource(changedItems.currentValue);
      // metto a lowercase i valori stringa per ordinare in case insensitive (non fa conto se il valore è minuscole o maiuscole)
      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
        return data[sortHeaderId];
      };
      // inizializzo il sorting e il paginator FRONTEND
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    }
  }

  onPaginateChange(pageEvent) {
    if (this.page.size != pageEvent.pageSize) {
      this.page.size = pageEvent.pageSize;
      this.page.number = 0;
    } else {
      this.page.number = pageEvent.pageIndex;
    }
    this.pageSelected.emit({ page: this.page, sort: this.sort });
  }

  sortData(sort: Sort) {
    this.sort = sort;
    this.pageSelected.emit({ page: this.page, sort: this.sort });
  }

}
