import { Component, OnInit, Input } from '@angular/core';
import { Flotta } from 'src/app/core/models/flotta';

@Component({
  selector: 'app-card-attivita',
  templateUrl: './card-attivita.component.html',
  styleUrls: ['./card-attivita.component.scss'],
})
export class CardAttivitaComponent implements OnInit {
  /* Attivita da erogare e da chiudere */
  @Input() targa: string;
  @Input() modelloAuto: string;
  @Input() tipoServizio: string;
  @Input() testo: string;
  /* Attivita flotte */
  @Input() nomeFlotta: string;
  @Input() statoFlotta: string;
  @Input() statoText: string;
  /* Entrambe */
  icon: string;
  @Input() isFlotta: boolean = false;
  @Input() isDaChiudere: boolean = false;

  flotta:Flotta;

  constructor() { }

  ngOnInit() {
    if (this.tipoServizio == 'lavaggio') {
      this.icon = "J";
    }
    else if (this.tipoServizio == 'rifornimento') {
      this.icon = "N";
    }
    else if (this.tipoServizio == 'parcheggio') {
      this.icon = "M";
    }
    else if (this.tipoServizio == 'trasferimento') {
      this.icon = "Q";
    }
    else if (this.tipoServizio == 'servizioHotel') {
      this.icon = "A";
    }
    else if (this.tipoServizio == 'rifornimentoEsterno') {
      this.icon = "O";
    }
  }

  getClassAttivita(tipoServizio) {

    if (this.isDaChiudere) {
      return {
        'chiudiLavaggio': tipoServizio == 'lavaggio',
        'chiudiRifornimento': tipoServizio == 'rifornimento',
        'chiudiParcheggio': tipoServizio == 'parcheggio',
        'chiudiTrasferimento': tipoServizio == 'trasferimento',
        'chiudiServizioHotel': tipoServizio == 'servizioHotel',
        'chiudiRifornimentoEsterno': tipoServizio == 'rifornimentoEsterno'
      }
    } else {
      return {
        'lavaggio': tipoServizio == 'lavaggio',
        'rifornimento': tipoServizio == 'rifornimento',
        'parcheggio': tipoServizio == 'parcheggio',
        'trasferimento': tipoServizio == 'trasferimento',
        'servizioHotel': tipoServizio == 'servizioHotel',
        'rifornimentoEsterno': tipoServizio == 'rifornimentoEsterno'
      }
    }
  }

  getClassAttivitaFlotte(stato) {
    return {
      'daConfermare': stato == 'daConfermare',
      'rifiutata': stato == 'rifiutata',
      'approvata': stato == 'approvata',
    }
  }

  getClassAttivitaFlotteBadge(stato) {
    return {
      'badgeDaConfermare': stato == 'daConfermare',
      'badgeRifiutata': stato == 'rifiutata',
      'badgeApprovata': stato == 'approvata',
    }
  }

}
