import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-conferma',
  templateUrl: './modal-conferma.component.html',
  styleUrls: ['./modal-conferma.component.scss'],
})
export class ModalConfermaComponent implements OnInit {

  messaggio : string = 'No message available';

  constructor(public dialogRef: MatDialogRef<ModalConfermaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.messaggio = this.data.messaggio;
     }

  ngOnInit() {}

  procedi() {
    this.dialogRef.close(true);
  }

  annulla() {
    this.dialogRef.close(false);
  }

}
